import { Col, Row, Dropdown, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { Button, Card, ConnectedStatus, Heading, IntegrationHeader } from 'components/common'
import useStore from 'store/useStore'

import type { Params } from 'react-router-dom'

import type { Integration } from 'models/Integration.model'

// This component can likely be fully removed, will confirm.
const IntegrationPage = observer(() => {
  const { id = '' }: Readonly<Params<string>> = useParams()
  const { integrationsStore } = useStore()
  const navigate = useNavigate()
  const { get, currentIntegration, isLoading } = integrationsStore
  const connections = currentIntegration?.connections

  useEffectOnce(() => {
    get(id).catch(console.error)
  })

  const redirectToAddIntegration = useCallback((id) => navigate(`/integrations/${id}/add`), [navigate])

  const addConnectionButton = (integration: Integration) => {
    if (connections && connections.length >= 1 && currentIntegration.id !== 'snowflake') {
      return null
    }

    return (
      <Button
        text='Add new connection'
        type='secondary'
        onClickHandler={() => {
          redirectToAddIntegration(integration.id)
        }}
      />
    )
  }

  if (isLoading) {
    return (
      <div className='integrations-loading-container'>
        <Spin size='large' />
      </div>
    )
  }

  return (
    <main className='bg-secondary integrations-main'>
      <div className='container'>
        {currentIntegration && (
          <IntegrationHeader
            integrationId={currentIntegration.id}
            displayName={currentIntegration.name}
            iconUrl={currentIntegration.iconUrl}
            iconAlt={currentIntegration.id}
            linkTo='/integrations'>
            <div className='c-button--right'>{addConnectionButton(currentIntegration)}</div>
          </IntegrationHeader>
        )}
        {connections?.map((connection) => (
          <Card variant='1' key={connection.id} className='integrations-main-card'>
            <Row align='middle'>
              <Col sm={11} md={13} lg={15} xl={16}>
                <Heading level='3' variant='4'>
                  {connection.name}
                </Heading>
              </Col>
              <Col sm={11} md={9} lg={8} xl={6}>
                <ConnectedStatus status='Connected' date={connection.connectionDate} />
              </Col>
              <Col sm={2} lg={1} xl={2}>
                <Dropdown.Button menu={{}} className='c-drop-down'></Dropdown.Button>
              </Col>
            </Row>
          </Card>
        ))}
      </div>
    </main>
  )
})

export default IntegrationPage
