import { makeAutoObservable, runInAction, toJS } from 'mobx'

import { API } from 'api/api'
import type { ChildStore } from 'store/StoreTypes'

import type {
  AggregationData,
  AggregationDataResult,
  AggregationOptions,
  CurrentUsedAggregation,
  ConfigPanelPayload,
  SelectOptions,
} from 'models/motion/motionBuilder.model'

export class AggregationsDataStore implements ChildStore {
  currentItem: AggregationData | null = null
  aggregationsDataModal: { isOpen: boolean; isEditing: boolean } = {
    isOpen: false,
    isEditing: false,
  }
  aggregationsOptions: AggregationOptions = {
    isLoading: false,
    aggregationLevel: [],
    aggregationTypes: [],
  }
  currentAggregationData: AggregationData = {
    name: '',
    aggregationLevel: '',
    aggregationType: '',
  }
  currentUuid: string | null = null
  currentUsedAggregations: CurrentUsedAggregation[] = []
  displayRemoveModal: boolean = false
  aggregationsList: AggregationData[] = []

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.currentItem = null
    this.aggregationsDataModal = {
      isOpen: false,
      isEditing: false,
    }
    this.aggregationsOptions = {
      isLoading: false,
      aggregationLevel: [],
      aggregationTypes: [],
    }
    this.currentAggregationData = {
      name: '',
      aggregationLevel: '',
      aggregationType: '',
    }
    this.currentUuid = null
    this.currentUsedAggregations = []
    this.displayRemoveModal = false
    this.aggregationsList = []
  }

  setCurrentItem = (item: AggregationData) => {
    this.currentItem = item

    if (item) {
      const data = this.aggregationsList.find((aggregation) => aggregation.aggregationUuid === item.aggregationUuid)

      if (data) {
        this.setCurrentAggregationData({
          aggregationLevel: data.aggregationLevel,
          aggregationType: data.aggregationType,
          aggregationUuid: data.aggregationUuid,
          filters: data.filters,
          field: data.key || data.field,
          name: data.name,
          object: data.object,
          platform: data.platform,

          solutionInstanceId: data.solutionInstanceId,
        })
      }
    }
    this.aggregationsDataModal.isOpen = true
  }

  setDisplayAggregationModal = (state: boolean, isEditing = false) => {
    this.aggregationsDataModal = {
      isOpen: state,
      isEditing,
    }
  }

  getAggregationOptions = async () => {
    if (!this.currentItem || !this.aggregationsDataModal.isOpen) {
      return
    }

    this.aggregationsOptions.isLoading = true
    const result = await API.metadata.getAggregations({
      platform: this.currentItem.platform,
      object: this.currentItem.object,
      field: this.currentItem.key || this.currentItem.field,
      solutionInstanceId: this.currentItem.solutionInstanceId,
    })
    runInAction(() => {
      if (result instanceof Error) {
        this.aggregationsOptions = {
          isLoading: false,
          aggregationLevel: [],
          aggregationTypes: [],
        }
      } else {
        this.setAggregationsOptions(result)
      }
    })
    this.aggregationsOptions.isLoading = false
  }

  setAggregationsOptions = (result: AggregationDataResult) => {
    this.aggregationsOptions = {
      isLoading: false,
      aggregationLevel: this.getAggregationLevelOptions(result.aggregationLevel),
      aggregationTypes: result.aggregationTypes.map((type) => {
        return { label: type, value: type }
      }),
    }

    if (!this.currentAggregationData.aggregationLevel) {
      this.currentAggregationData.aggregationLevel = this.aggregationsOptions.aggregationLevel[0].value || ''
    }

    if (!this.currentAggregationData.aggregationType) {
      this.currentAggregationData.aggregationType = this.aggregationsOptions.aggregationTypes[0].value || ''
    }

    if (!this.aggregationsDataModal.isEditing) {
      this.currentAggregationData = {
        ...this.currentAggregationData,
        aggregationLevel: this.aggregationsOptions.aggregationLevel[0].value || '',
        aggregationType: this.aggregationsOptions.aggregationTypes[0].value || '',
      }
    }
  }

  setCurrentAggregationData = (data: AggregationData) => {
    this.currentAggregationData = toJS(data)
  }

  getAggregationLevelOptions = (aggregationLevel: { [level: string]: boolean }) => {
    const options: SelectOptions[] = []
    Object.keys(aggregationLevel).forEach((level) => {
      if (aggregationLevel[level]) {
        options.push({ label: level.charAt(0).toUpperCase() + level.slice(1), value: level })
      }
    })

    return options
  }

  setAggregationName = (
    name: boolean | number | number[] | string | string[] | moment.Moment | moment.Moment[] | null,
  ) => {
    this.currentAggregationData.name = name as string
  }

  setAggregationType = (
    type: boolean | number | number[] | string | string[] | moment.Moment | moment.Moment[] | null,
  ) => {
    this.currentAggregationData.aggregationType = type as string
  }

  setAggregationLevel = (
    level: boolean | number | number[] | string | string[] | moment.Moment | moment.Moment[] | null,
  ) => {
    this.currentAggregationData.aggregationLevel = level as string
  }

  saveAggregationData = (filters: ConfigPanelPayload) => {
    if (this.aggregationsDataModal.isEditing) {
      this.aggregationsList[
        this.aggregationsList.findIndex(
          (aggregation) => aggregation.aggregationUuid === this.currentAggregationData.aggregationUuid,
        )
      ] = {
        ...toJS(this.currentAggregationData),
        filters: toJS(filters),
        ...(!this.currentAggregationData.name && { name: `${this.currentItem?.key}_aggregation` }),
      }
    } else {
      this.aggregationsList.push({
        ...this.currentAggregationData,
        ...(!this.currentAggregationData.name && { name: `${this.currentItem?.key}_aggregation` }),
        aggregationUuid: window.crypto.randomUUID(),
        platform: this.currentItem?.platform,
        object: this.currentItem?.object,
        field: this.currentItem?.key,
        filters: toJS(filters),
        solutionInstanceId: this.currentItem?.solutionInstanceId,
        ...(this.currentItem?.magnifyDisplayName && { magnifyDisplayName: this.currentItem?.magnifyDisplayName }),
      })
    }

    this.aggregationsDataModal = {
      isOpen: false,
      isEditing: false,
    }
  }

  setAggregationsList = (list: AggregationData[] | undefined) => {
    this.aggregationsList = list ?? []
  }

  removeItemFromAggregationsList = (aggregationUuid?: string) => {
    runInAction(() => {
      const index = this.aggregationsList.findIndex((item: AggregationData) => item.aggregationUuid === aggregationUuid)
      this.aggregationsList.splice(index, 1)
    })
  }

  setCurrentUuid = (uuid: string | null) => {
    this.currentUuid = uuid
  }

  setCurrentUsedAggregations = (data: CurrentUsedAggregation[] | null) => {
    if (data?.length) {
      this.currentUsedAggregations = data
    } else {
      this.currentUsedAggregations = []
    }
  }

  setDisplayRemoveModal = (value: boolean) => {
    this.displayRemoveModal = value
  }
}
