export const demoGetActions = [
  {
    iconName: 'lightBulb',
    actionName: 'Launch Guide',
    metadataObject: 'Guide',
    platform: 'pendo',
    isHidden: false,
    id: '0dc5638b-7dd0-466d-8714-7a47c1f9f8ae',
    type: 'create',
  },
  {
    iconName: 'triggerEvent',
    actionName: 'Trigger Event',
    metadataObject: 'Trigger_Event',
    platform: 'gainsight',
    actionLevel: 'user',
    id: '333b9438-ce6f-426a-88d3-e7180907ca2c',
    type: 'create',
  },
  {
    iconName: 'createCrown',
    actionName: 'Create Opportunity',
    metadataObject: 'Opportunity',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '98d26a16-34b3-4eb3-b5ca-de8d6b6bdd5b',
    type: 'create',
  },
  {
    iconName: 'updateCrown',
    actionName: 'Update Opportunity',
    metadataObject: 'Opportunity',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '95104dac-cd7b-4ba0-90f5-535d0238cf51',
    type: 'update',
  },
  {
    iconName: 'createFiles',
    actionName: 'Create Ticket',
    metadataObject: 'Ticket',
    platform: 'zendesk',
    actionLevel: 'account',
    id: '9b724756-0f99-498d-abfd-9f440f629eb6',
    type: 'create',
  },
  {
    iconName: 'createCustomObject',
    actionName: 'Create Case',
    metadataObject: 'Case',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '6ed6ebf8-f2c4-4c5c-883b-7faf163dd882',
    type: 'create',
  },
  {
    iconName: 'updateUser',
    actionName: 'Update Account',
    metadataObject: 'Account',
    platform: 'salesforce',
    actionLevel: 'account',
    id: '103eee45-2d0e-4928-a1ca-e4e6bfe88a8f',
    type: 'update',
  },
  {
    iconName: 'createCustomObject',
    actionName: 'Create CTA',
    metadataObject: 'Call_To_Action',
    platform: 'gainsight',
    actionLevel: 'user',
    id: '5341a409-3fcc-4409-a2ae-fcaa29cd03be',
    type: 'create',
  },
  {
    iconName: 'sendEmail',
    actionName: 'Send Email',
    metadataObject: 'Campaign',
    platform: 'marketo',
    actionLevel: 'user',
    isHidden: false,
    id: '776b77df-da11-4a84-abe8-37955da11b00',
    type: 'create',
  },
  {
    iconName: 'sendEmail',
    actionName: 'Send Email',
    metadataObject: 'Email',
    platform: 'magnify',
    actionLevel: 'user',
    id: '7a01af85-1d1b-4f0e-932b-ee9ca1f6c38d',
    type: 'create',
  },
  {
    iconName: 'updateFiles',
    actionName: 'Update Ticket',
    metadataObject: 'Ticket',
    platform: 'zendesk',
    actionLevel: 'account',
    id: '770017f9-cca5-4713-9d15-b680f4f23c0c',
    type: 'update',
  },
  {
    iconName: 'createNote',
    actionName: 'Add Ticket Comment',
    metadataObject: 'Ticket.Comment',
    platform: 'zendesk',
    actionLevel: 'account',
    id: '717ecb6d-38ef-446c-9cd2-a50ce1329584',
    type: 'update',
  },
  {
    iconName: 'createCalendar',
    actionName: 'Create Timeline Event',
    metadataObject: 'Activity_Timeline',
    platform: 'gainsight',
    actionLevel: 'account',
    id: '3f6fde7c-d2b0-49c7-8367-8fc19f7b0c03',
    type: 'create',
  },
  {
    iconName: 'sendMessage',
    actionName: 'Send Message',
    metadataObject: 'Message',
    platform: 'slack',
    actionLevel: 'user',
    isHidden: false,
    id: 'e382bf19-5ee0-4b20-b777-479c3f641e90',
    type: 'create',
  },
  {
    iconName: 'sendMessage',
    actionName: 'Post to Channel',
    metadataObject: 'Channel',
    platform: 'slack',
    actionLevel: 'user',
    isHidden: false,
    id: '4306b7e2-e4d0-4f2a-b93c-9da858a4e349',
    type: 'create',
  },
  {
    iconName: 'createCustomObject',
    actionName: 'Create Request',
    metadataObject: 'Request',
    platform: 'zendesk',
    actionLevel: 'account',
    id: 'b5299e49-5ad8-443b-b027-236c8d205edd',
    type: 'create',
  },
  {
    iconName: 'createBriefcase',
    actionName: 'Create Task',
    metadataObject: 'Task',
    platform: 'salesforce',
    actionLevel: 'account',
    id: 'baeb3b22-91c9-4295-a84f-568824316e7b',
    type: 'create',
  },
  {
    iconName: 'createFiles',
    actionName: 'Create Ticket',
    metadataObject: 'Tickets',
    platform: 'intercom',
    actionLevel: 'user',
    isHidden: false,
    id: 'eb3e0b4a-c2e4-46e2-aa88-bd4724a95dfe',
    type: 'create',
  },
  {
    iconName: 'createCrown',
    actionName: 'Create or Update Company',
    metadataObject: 'Companies',
    platform: 'intercom',
    actionLevel: 'account',
    isHidden: false,
    id: '1ed44390-336d-4388-b6ea-f75526265b6f',
    type: 'create',
  },
  {
    iconName: 'sendEmail',
    actionName: 'Send Transactional Email',
    metadataObject: 'Send_Transactional_Email',
    platform: 'hubspot',
    actionLevel: 'user',
    isHidden: false,
    id: '7d8f029e-8d83-4490-950b-72c2da7a5e39',
    type: 'create',
  },
]
