import { makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { CreateSlackMessagePayload, SlackMessage } from 'models/slack-messages'

export const DEFAULT_PAGE_SIZE = 10

export class SlackMessagesStore implements ChildStore {
  loading = {
    isMessageLoading: false,
  }
  data = {
    message: {} as SlackMessage,
  }

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
    })
  }

  reset = () => {
    this.loading = {
      isMessageLoading: false,
    }
    this.data = {
      message: {} as SlackMessage,
    }
    this.apiError = null
  }

  resetSelectedTemplate = () => {
    this.loading = {
      isMessageLoading: false,
    }
    this.data = {
      message: {} as SlackMessage,
    }
    this.apiError = null
  }

  /**
   * Create a new slack message.
   * @param {CreateSlackMessagePayload} payload The payload to create a new slack message.
   * @returns {Promise<SlackMessage>} The created slack message
   */
  createMessage = async (payload: CreateSlackMessagePayload) => {
    this.loading.isMessageLoading = true
    try {
      const message = await API.slackMessages.create(payload)
      runInAction(() => {
        this.data.message = message
      })
      return message
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isMessageLoading = false
      })
    }
  }

  /**
   * Fetch a specific version of a slack message.
   * @returns {Promise<SlackMessage>} The requested slack message
   */
  getMessage = async (params: { slackMessageId: string; slackMessageVersion: number }) => {
    this.loading.isMessageLoading = true
    try {
      const template = await API.slackMessages.get(params)
      runInAction(() => {
        this.data.message = template
      })
      return template
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isMessageLoading = false
      })
    }
  }

  setTemplate = (template: SlackMessage) => {
    runInAction(() => {
      this.data.message = template
    })
  }

  /**
   * Update a message by ID.
   * @returns {Promise<SlackMessage>} The updated message.
   */
  updateMessage = async (params: { slackMessageId: string; payload: CreateSlackMessagePayload }) => {
    this.loading.isMessageLoading = true
    try {
      const template = await API.slackMessages.update(params)
      runInAction(() => {
        this.data.message = template
      })
      return template
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isMessageLoading = false
      })
    }
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
