import { Tooltip } from 'antd'

import { Logo } from 'components/common'
import { formatNumberWithAbbreviation } from 'components/common/Utils/insights'
import InsightContentHeading from 'components/Insights/components/InsightRow/components/InsightContent/components/InsightContentHeading'
import { buildHeadingForTooltip } from 'components/Insights/components/InsightRow/utils'

import type { MlMetricCategoryEnum } from 'models/account.model'
import {
  type InsightDriverSegmentMetricsV2,
  type InsightDriverSegmentV2,
  type DriverSegmentEnum,
  type ImpactEnum,
  type InsightDriverV2,
  InsightDriverTypeEnum,
} from 'models/insights'

interface InsightContentProps {
  insight: InsightDriverV2
  selectedTarget: MlMetricCategoryEnum
  insightSegment: InsightDriverSegmentV2
  insightLevel: DriverSegmentEnum
  insightImpactLevel: ImpactEnum
  isRevenueQuantified?: boolean
}

const InsightContent = ({
  insight,
  selectedTarget,
  insightSegment,
  insightLevel,
  insightImpactLevel,
  isRevenueQuantified,
}: InsightContentProps) => {
  const platform = insightSegment.family.platform
  const field = insightSegment.family.field
  const familyObj = insightSegment.family.object
  const metrics = insightSegment.metrics
  const propensityImprovement = insightSegment.propensity.improve_to_text
  const parsedMetrics = Object.entries(metrics).map((metricEntry: [string, InsightDriverSegmentMetricsV2]) => ({
    ...metricEntry[1],
    time_period: metricEntry[0],
  }))

  const generateTooltip = (metric: InsightDriverSegmentMetricsV2) => {
    if (insight.driver_type === InsightDriverTypeEnum.Insight && metric.improve_to_text) {
      return `Improve ${metric.time_period} to ${metric.improve_to_text} to ${buildHeadingForTooltip(
        selectedTarget,
      )} to ${propensityImprovement}`
    }
    return `Improve ${metric.time_period} to ${buildHeadingForTooltip(selectedTarget)} to ${propensityImprovement}`
  }

  return (
    <div className='insights-content' data-testid='insights-content'>
      <InsightContentHeading
        insight={insight}
        insightType={selectedTarget}
        insightSegment={insightSegment}
        isRevenueQuantified={isRevenueQuantified}
      />
      <div className='driver-details' data-testid='insight-row-insight-content-driver-details'>
        <div className='driver-details__item'>
          <Logo platformName={platform} bordered={false} />
          <p className='driver-details__label'>
            <span>{field}</span> from {familyObj}
          </p>
        </div>
        {parsedMetrics.map((metric: InsightDriverSegmentMetricsV2, index: number) => {
          if (index > 1) return null
          const improveFrom = formatNumberWithAbbreviation(metric.improve_from, 2)
          const improveTo = formatNumberWithAbbreviation(metric.improve_to, 2)
          const key = `${metric.time_period}-${metric.metric}-${improveFrom}-${improveTo}`
          return (
            <Tooltip title={generateTooltip(metric)} key={key}>
              <div className='driver-details__item driver-details__metric'>
                {metric.time_period} {metric.metric}:{' '}
                <span
                  className={`driver-details__metric__from driver-details__metric__from--${insightLevel}--${insightImpactLevel}`}>
                  {improveFrom}
                </span>{' '}
                {insight.driver_type === InsightDriverTypeEnum.Insight && improveTo ? (
                  <>
                    →{' '}
                    <span className='driver-details__metric__to' data-testid={`driver-details__metric__to-${index}`}>
                      {improveTo}
                    </span>
                  </>
                ) : null}
              </div>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}
InsightContent.displayName = 'InsightContent'

export default InsightContent
