import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import App from 'App'
import { Amplify } from 'aws-amplify'
import { asyncWithLDProvider, basicLogger } from 'launchdarkly-react-client-sdk'
import ScrollToTopProvider from 'providers/ScrollToTop'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { cognitoConfig } from 'configs/cognito.config'

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID || '',
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'magnify-front-end',
  env: import.meta.env.VITE_ENV,
  version: import.meta.env.VITE_APPLICATION_VERSION, // On a deployed environment, this refers to the current Git tag deployed
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  telemetrySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

datadogLogs.init({
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'magnify-front-end',
  env: import.meta.env.VITE_ENV,
  version: import.meta.env.VITE_APPLICATION_VERSION, // On a deployed environment, this refers to the current Git tag deployed
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['warn', 'error'],
  forwardReports: 'all',
  sessionSampleRate: 100,
  telemetrySampleRate: 0,
})

if (import.meta.env.VITE_ENV === 'production') {
  datadogRum.startSessionReplayRecording()
}

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId: String(cognitoConfig.USER_POOL_ID),
        userPoolClientId: String(cognitoConfig.APP_CLIENT_ID),
        identityPoolId: String(cognitoConfig.IDENTITY_POOL_ID),
        loginWith: {
          oauth: {
            domain: cognitoConfig.OAUTH.HOSTED_UI_DOMAIN,
            redirectSignIn: cognitoConfig.OAUTH.REDIRECT_SIGN_IN,
            redirectSignOut: cognitoConfig.OAUTH.REDIRECT_SIGN_OUT,
            responseType: cognitoConfig.OAUTH.RESPONSE_TYPE as 'code',
            scopes: cognitoConfig.OAUTH.SCOPES,
          },
        },
      },
    },
    Storage: {
      S3: {
        bucket: import.meta.env.VITE_SEGMENT_OUTPUT_S3_BUCKET,
        region: 'us-west-2',
      },
    },
  },
  {
    Storage: {
      S3: {
        // eslint-disable-next-line @typescript-eslint/require-await
        prefixResolver: async ({ accessLevel, targetIdentityId }) => {
          if (accessLevel === 'guest') {
            return ''
          } else if (accessLevel === 'protected') {
            return `/`
          } else {
            return `/`
          }
        },
      },
    },
  },
)

const renderApp = async () => {
  /**
   * Wait for LaunchDarkly to load before loading the app.
   * @see {@link https://docs.launchdarkly.com/sdk/client-side/react/react-web#initializing-using-asyncwithldprovider}
   * @see {@link https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html}
   */
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_DARKLY_CLIENT_SIDE_ID,
    options: {
      application: {
        version: import.meta.env.VITE_APPLICATION_VERSION,
      },
      diagnosticOptOut: true,
      evaluationReasons: true,
      logger: basicLogger({ level: 'error' }),
    },
  })

  ReactDOM.render(
    <main>
      <LDProvider>
        <BrowserRouter>
          <ScrollToTopProvider />
          <App />
        </BrowserRouter>
      </LDProvider>
    </main>,
    document.getElementById('root'),
  )
}

void renderApp()
