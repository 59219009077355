import { LinkOutlined } from '@ant-design/icons'
import { Col, message, Row, Tooltip } from 'antd'
import { Link } from 'react-router-dom'

import { Button, Card, ConnectedStatus } from 'components/common'
import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import Sandbox from 'configs/sandbox'
import { getIconUrl } from 'pages/Integrations/utils'

import type { Connection, IntegrationNew } from 'models/Integration.model'

const IntegrationCardNew = ({
  connections,
  displayName,
  id,
  showSupportModal,
  status,
}: IntegrationNew & { id: string; showSupportModal: () => void }) => {
  const { userOauthLink } =
    connections?.find((connection: Connection) => {
      return connection.userOauthLink
    }) ?? {}

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(userOauthLink ?? '')
      message.success('Link copied to clipboard!')
    } catch (error: unknown) {
      message.success('Failed to copy to clipboard')
    }
  }

  return (
    <Card variant='1' key={id} className='integrations-main-card'>
      <Row className='c-flex--center'>
        <Col xs={1} md={1} lg={1} xl={1}>
          <DynamicImage iconUrl={getIconUrl(id)} iconAlt={displayName} />
        </Col>
        <Col xs={13} md={10} lg={12} xl={13}>
          <span className='c-source--name'>{displayName}</span>
        </Col>
        <Col xs={8} md={6} lg={6} xl={5} className='c-flex--start'>
          <ConnectedStatus
            connections={connections}
            status={(connections?.length ?? 0) > 1 ? (status ?? '') : 'Connected'}
          />
        </Col>
        <Col xs={22} md={6} lg={5} xl={5}>
          {connections?.length ? (
            <Button
              type='secondary'
              onClickHandler={showSupportModal}
              style={{ width: '145px' }}
              text='Edit'
              testId='edit-integration-new'
              disabled={Sandbox.isEnabled()}
            />
          ) : (
            <Link
              className={`button button-primary button-XL ${Sandbox.isEnabled() && 'button--disabled'}`}
              to={!Sandbox.isEnabled() ? `/integrations/${id}/addNew` : ''}
              data-testid='connect-button'
              style={{ width: '145px' }}>
              Connect
            </Link>
          )}

          {userOauthLink && (
            <Tooltip placement='top' title={'Share this link with your team'}>
              <div className='share-container'>
                <Button
                  link
                  icon={{
                    element: <LinkOutlined />,
                  }}
                  className='share-link'
                  onClickHandler={() => {
                    handleCopyToClipboard().catch(console.error)
                  }}
                  text='Share link'
                />
              </div>
            </Tooltip>
          )}
        </Col>
      </Row>
    </Card>
  )
}
IntegrationCardNew.displayName = 'IntegrationCardNew'

export default IntegrationCardNew
