import { useMemo } from 'react'

import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import { Heading } from 'components/common/Heading/Heading'
import { getIconUrl } from 'pages/Integrations/utils'
import { ConnectionStatus } from 'pages/IntegrationsV2/ConnectionStatus/ConnectionStatus'
import { countStatusByCategory, groupIntegrationsByCategory } from 'pages/IntegrationsV2/utils'

import type { IntegrationV2, IntegrationV2ConnectionStatus } from 'models/Integration.model'

interface AvailableConnectionsProps {
  integrations: IntegrationV2[]
}

export const AvailableConnections = ({ integrations }: AvailableConnectionsProps) => {
  const { groupedIntegrationsByCategory, statusCountByCategory } = useMemo(() => {
    return {
      groupedIntegrationsByCategory: groupIntegrationsByCategory(integrations),
      statusCountByCategory: countStatusByCategory(integrations),
    }
  }, [integrations])

  return (
    <div className='available-connections'>
      {Object.keys(groupedIntegrationsByCategory).map((category) => {
        return (
          <div key={category} className='available-connections__card'>
            <div className='available-connections__card__heading'>
              <Heading variant='4' level='4' className='available-connections__card__heading__title'>
                {category}
              </Heading>
              <div className='available-connections__card__heading__status'>
                {Object.keys(statusCountByCategory[category]).map((status) => {
                  if (!statusCountByCategory[category][status]) return null
                  return (
                    <ConnectionStatus
                      key={`${category}-${status}`}
                      text={statusCountByCategory[category][status].toString()}
                      status={status as IntegrationV2ConnectionStatus}
                    />
                  )
                })}
              </div>
            </div>

            <div className='available-connections__card__platforms'>
              {groupedIntegrationsByCategory[category].map((integration) => {
                return (
                  <div key={integration.id} className='available-connections__card__platforms__platform'>
                    <DynamicImage
                      key={integration.id}
                      iconUrl={getIconUrl(integration.id)}
                      iconAlt={integration.displayName}
                    />
                    <span>{integration.displayName}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
AvailableConnections.displayName = 'AvailableConnections'
