import axios from 'axios'

import { demoGetObservabilityInMotionReportingTotals } from 'api//mockResponses/demo/observability.mock'
import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import {
  demoGetObservabilityTenantActionTotals,
  demoGetObservabilityTenantActionTotalsRange,
  demoGetObservabilityTenantMotionTotals,
} from 'api/mockResponses/demo/observability.mock'
import { getBaseUrl } from 'api/utils'
import Sandbox from 'configs/sandbox'
import { LoggerService } from 'services/LogService/LogService'

import type {
  DateRangeProps,
  TenantActionTotals,
  TenantInMotionReportingTotals,
  TenantMotionTotals,
} from 'models/observability.model'

export const getObservabilityTenantActionTotals = async (options?: DateRangeProps) => {
  try {
    const { data } = await axios.get<TenantActionTotals>(
      `${getBaseUrl('CORE_API')}/v1/core/observability/actions/totals`,
      {
        demoData: options ? demoGetObservabilityTenantActionTotalsRange : demoGetObservabilityTenantActionTotals,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
        params: options,
      } as DemoAxiosRequestConfig,
    )

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get getObservabilityTenantActionTotals error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Observability Tenant Actions Totals')
    }
  }
}

export const getObservabilityTenantMotionTotals = async (options?: DateRangeProps) => {
  try {
    const { data } = await axios.get<TenantMotionTotals>(
      `${getBaseUrl('CORE_API')}/v1/core/observability/journey-participants/totals`,
      {
        demoData: demoGetObservabilityTenantMotionTotals,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
        params: options,
      } as DemoAxiosRequestConfig,
    )

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get getObservabilityTenantMotionTotals error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Observability Tenant Motions Totals')
    }
  }
}

export const getObservabilityInMotionReportingTotals = async (motionId: string) => {
  try {
    const { data } = await axios.get<TenantInMotionReportingTotals>(
      `${getBaseUrl('CORE_API')}/v1/core/observability/journeys/${motionId}/nodes/totals`,
      {
        demoData:
          demoGetObservabilityInMotionReportingTotals[
            motionId as keyof typeof demoGetObservabilityInMotionReportingTotals
          ],
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get getObservabilityInMotionReportingTotals error', error })
    if (axios.isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Observability in Motion reporting totals')
    }
  }
}
