import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useDatadogRumTracking = () => {
  const location = useLocation()

  useEffect(() => {
    datadogRum.startView(location.pathname)
  }, [location])
}

export default useDatadogRumTracking
