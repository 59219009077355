import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { AccountDetails, MlMetricCategoryEnum, ParticipatedMotion } from 'models/account.model'
import type {
  AccountData,
  AccountForecast,
  AccountForecastTableRow,
  AccountForecastTableState,
  AccountRowValue,
  DriverSegmentAccounts,
  InsightDimensions,
  InsightEventV2,
  InsightsV2,
  InsightV2,
  RevenueRetentionForecast,
  SetTargetPost,
} from 'models/insights'
import { ImpactEnum } from 'models/insights'

// We do this again here to ensure it works in the tests as the setup is missed in the test setup
dayjs.extend(utc)

/**
 * Sorts the quarters in the format of `Qn'YY`
 * @param {string} a The first quarter to compare
 * @param {string} b The second quarter to compare
 * @returns {number} The sorting order of the two quarters
 */
export const sortQuarters = (a: string, b: string) => {
  // Ensure "Overall" is always first
  if (a === 'Overall') return -1
  if (b === 'Overall') return 1

  // Doesn't start with Q, so we sort by string.
  if (!a.startsWith('Q') || !b.startsWith('Q')) {
    return a.localeCompare(b)
  }

  // Extracting the year from each string
  const yearA = Number.parseInt(a.substring(3), 10)
  const yearB = Number.parseInt(b.substring(3), 10)

  // Compare the years
  if (yearA !== yearB) {
    return yearA - yearB
  }

  // If years are the same, compare quarters
  const quarterA = Number.parseInt(a.substring(1, 2), 10)
  const quarterB = Number.parseInt(b.substring(1, 2), 10)

  return quarterA - quarterB
}

export const initCurrentAccountDetails: AccountDetails = {
  granular: '',
  datetime: '',
  dataset_date: '',
  account_id: '',
  account_name: '',
  user_count: 0,
  usages: {
    revenues: {
      trend: [],
      lift: { from: '', to: '', value: '', percent: 0 },
    },
  },
  events: {},
}

export const prepareAccounts = (accounts: DriverSegmentAccounts, target: string): AccountData => {
  if (accounts.table_columns && accounts.table_rows) {
    return {
      tableColumns: accounts.table_columns,
      tableRows: accounts.table_rows,
      impact: accounts.event.impact,
    }
  }

  return {} as AccountData
}

/**
 * Shortens a string float to 0 if it is very small
 */
function shortenFloat(input: string) {
  let value = Number.parseFloat(input)
  if (Number.isNaN(value)) {
    value = 0
  }
  if (Math.abs(value) < 1e-20) {
    value = 0
  }
  return value
}

export class InsightsStore implements ChildStore {
  dimensions: InsightDimensions | null = null
  selectedDimension: string = 'overall'
  insights: InsightsV2 | null = null
  selectedTarget: InsightEventV2 | null = null
  currentInsight: InsightV2 | null = null
  accountData: AccountData = {
    tableColumns: [],
    tableRows: [],
    impact: ImpactEnum.Positive,
  }
  accountForecast: AccountForecast | null = {
    grid: 'account',
    dimension: 'overall',
    train_datetime: new Date().toISOString(),
    infer_datetime: new Date().toISOString(),
    dataset_date: new Date().toISOString().split('T')[0],
    is_revenue_quantified: true,
    filter_columns: [],
    filter_values: {
      contract_end_period: [],
      revenue_period: [],
    },
    table_columns: [],
    table_rows: [],
    // New Format
    results: [],
    contractPeriodEnds: [],
    revenuePeriods: [],
    totalSize: 0,
  }
  accountForecastTableState: AccountForecastTableState = {
    selectedRenewal: '',
    selectedForecast: '',
    searchInput: '',
    currentPage: 1,
    pageSize: 10,
    sortKey: 'contractEndDate',
    sortDirection: 'asc',
  }
  customSegmentAccountData: AccountData = {
    tableColumns: [],
    tableRows: [],
    impact: ImpactEnum.Positive,
  }
  selectedAccounts: AccountRowValue[] = []
  selectedCustomSegmentAccounts: AccountRowValue[] = []
  currentAccountDetails: AccountDetails = initCurrentAccountDetails
  currentParticipatedMotions: ParticipatedMotion[] | null = null
  revenueRetentionForecast: RevenueRetentionForecast | null = null

  apiError: CoreAPIErrorResponse | null = null
  apiErrorAllInsights: CoreAPIErrorResponse | null = null

  isLoadingAccount = false
  isLoadingAccountForecast = false
  isLoadingAccountDetails = false
  isLoadingAllInsights = false
  isLoadingDimensions = false
  isLoadingInsight = false
  isLoadingParticipatedMotions = false
  isLoadingSegment = false
  isLoadingTarget = false
  isLoadingRevenueRetentionForecast = false
  isRecalculating = false

  constructor() {
    makeAutoObservable(this, {
      accountData: observable,
      accountForecast: observable,
      accountForecastTableState: observable,
      apiError: observable,
      apiErrorAllInsights: observable,
      dimensions: observable,
      insights: observable,
      isLoadingAccount: observable,
      isLoadingAccountForecast: observable,
      isLoadingAccountDetails: observable,
      isLoadingAllInsights: observable,
      isLoadingDimensions: observable,
      isLoadingInsight: observable,
      isLoadingParticipatedMotions: observable,
      isLoadingSegment: observable,
      isLoadingTarget: observable,
      isLoadingRevenueRetentionForecast: observable,
      isLoading: computed,
      selectedDimension: observable,
      selectedTarget: observable,
      currentAccountDetails: observable,
      currentParticipatedMotions: observable,
      revenueRetentionForecast: observable,
      targetOptions: computed,
      setSelectedDimension: action,
      setSelectedTarget: action,
      setCurrentAccountDetails: action,
      setAccountForecastTableState: action,
      setApiError: action,
    })
  }

  reset = () => {
    this.dimensions = null
    this.selectedDimension = 'overall'
    this.insights = null
    this.selectedTarget = null
    this.currentInsight = null
    this.accountData = {
      tableColumns: [],
      tableRows: [],
      impact: ImpactEnum.Positive,
    }
    this.accountForecast = {
      grid: 'account',
      dimension: 'overall',
      train_datetime: new Date().toISOString(),
      infer_datetime: new Date().toISOString(),
      dataset_date: new Date().toISOString().split('T')[0],
      is_revenue_quantified: true,
      filter_columns: [],
      filter_values: {
        contract_end_period: [],
        revenue_period: [],
      },
      table_columns: [],
      table_rows: [],
      // New Format
      results: [],
      contractPeriodEnds: [],
      revenuePeriods: [],
      totalSize: 0,
    }
    this.accountForecastTableState = {
      selectedRenewal: '',
      selectedForecast: '',
      searchInput: '',
      currentPage: 1,
      pageSize: 10,
      sortKey: 'contractEndDate',
      sortDirection: 'asc',
    }
    this.customSegmentAccountData = {
      tableColumns: [],
      tableRows: [],
      impact: ImpactEnum.Positive,
    }
    this.selectedAccounts = []
    this.selectedCustomSegmentAccounts = []

    this.currentAccountDetails = initCurrentAccountDetails
    this.currentParticipatedMotions = null
    this.revenueRetentionForecast = null
    this.apiError = null
    this.apiErrorAllInsights = null
    this.isLoadingAccount = false
    this.isLoadingAccountForecast = false
    this.isLoadingAccountDetails = false
    this.isLoadingAllInsights = false
    this.isLoadingDimensions = false
    this.isLoadingInsight = false
    this.isLoadingParticipatedMotions = false
    this.isLoadingSegment = false
    this.isLoadingTarget = false
    this.isLoadingRevenueRetentionForecast = false
    this.isRecalculating = false
  }

  /** Generalized check for any loading insights. */
  get isLoading(): boolean {
    return (
      this.isLoadingAccount ||
      this.isLoadingAccountForecast ||
      this.isLoadingAccountDetails ||
      this.isLoadingAllInsights ||
      this.isLoadingDimensions ||
      this.isLoadingInsight ||
      this.isLoadingParticipatedMotions ||
      this.isLoadingRevenueRetentionForecast ||
      this.isLoadingSegment
    )
  }

  get targetOptions(): string[] {
    if (this.insights !== null) {
      return Object.keys(this.insights.events)
    } else {
      return []
    }
  }

  getDimensions = async (): Promise<void> => {
    this.isLoadingDimensions = true
    try {
      const dimensions = await API.insights.getDimensions()
      runInAction(() => {
        if (dimensions !== null) {
          this.dimensions = dimensions
        }
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingDimensions = false
      })
    }
  }

  getAllInsights = async ({ dimension }: { dimension: string }): Promise<void> => {
    this.isLoadingAllInsights = true
    try {
      const insights = await API.insights.getAllInsights({ dimension })

      runInAction(() => {
        if (insights !== null) {
          this.insights = insights
          const targetInsight = Object.entries(this.insights.events)[0]

          this.selectedTarget = {
            ...targetInsight[1],
            short_name: targetInsight[0] as MlMetricCategoryEnum,
          }
        }
      })
    } catch (error: unknown) {
      runInAction(() => {
        this.apiErrorAllInsights = error as CoreAPIErrorResponse
      })
    } finally {
      runInAction(() => {
        this.isLoadingAllInsights = false
      })
    }
  }

  fetchInsight = async ({
    dimension,
    id,
    target,
  }: {
    dimension: string
    id: string
    target: string
  }): Promise<void> => {
    this.isLoadingInsight = true
    try {
      const insight = await API.insights.getInsightSegmentInfo({ dimension, target, id })

      runInAction(() => {
        if (insight) {
          this.setCurrentInsight({ ...insight, driver_id: id })
        }
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
      runInAction(() => {
        this.currentInsight = null
      })
    } finally {
      runInAction(() => {
        this.isLoadingInsight = false
      })
    }
  }

  fetchAccountForecast = async ({
    page = 1,
    pageSize = 10,
    sortKey = '',
    sortDirection = 'ascend',
    accountLike = '',
    contractEndPeriod = '',
    revenuePeriod = '',
  }: {
    page: number
    pageSize: number
    sortKey: string
    sortDirection: string
    accountLike?: string
    contractEndPeriod?: string
    revenuePeriod?: string
  }): Promise<void> => {
    this.isLoadingAccountForecast = true
    try {
      let accountForecast = await API.insights.getAccountForecast({
        page,
        pageSize,
        sortKey,
        sortDirection,
        accountLike,
        contractEndPeriod,
        revenuePeriod,
      })

      if (!accountForecast) {
        accountForecast = {} as AccountForecast
      }

      if (!accountForecast?.results) {
        accountForecast.results = []
      }

      // Clean up the data
      accountForecast.results =
        accountForecast?.results.map((result) => {
          return {
            ...result,
            churn: {
              value: shortenFloat(result.churn),
              segment: Number.parseInt(result.churnSegment, 10),
            },
            expansion: {
              value: shortenFloat(result.expansion),
              segment: Number.parseInt(result.expansionSegment),
            },
            revenue: shortenFloat(result.revenue as string),
            contractEndDate: [null, undefined, '', 'null'].includes(result.contractEndDate)
              ? 'N/A'
              : dayjs(result.contractEndDate).utc().format('YYYY-MM-DD'),
          } as unknown as AccountForecastTableRow
        }) ?? []

      accountForecast.contractPeriodEnds = accountForecast?.contractPeriodEnds?.filter(Boolean)?.sort(sortQuarters)
      accountForecast.revenuePeriods = accountForecast?.revenuePeriods?.filter(Boolean)?.sort(sortQuarters)
      accountForecast.totalSize = Number.parseInt((accountForecast?.totalSize as unknown as string) ?? '0', 10)

      runInAction(() => {
        this.accountForecast = accountForecast
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingAccountForecast = false
      })
    }
  }

  fetchAccount = async ({
    dimension,
    id,
    target,
  }: {
    dimension: string
    id: string
    target: string
  }): Promise<void> => {
    this.isLoadingAccount = true
    try {
      const accounts = await API.insights.getInsightSegmentAccounts({ dimension, target, id })

      if (accounts) {
        const accountData = prepareAccounts(accounts, target)
        runInAction(() => {
          this.accountData = accountData
        })
      }
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingAccount = false
      })
    }
  }

  fetchAccountDetails = async ({ dimension, id }: { dimension: string; id: string }): Promise<void> => {
    this.isLoadingAccountDetails = true
    try {
      const accountDetails = await API.insights.getAccountDetails({ dimension, id })

      runInAction(() => {
        this.setCurrentAccountDetails(accountDetails)
      })
    } catch (error: unknown) {
      runInAction(() => {
        this.setCurrentAccountDetails(initCurrentAccountDetails)
      })
    } finally {
      runInAction(() => {
        this.isLoadingAccountDetails = false
      })
    }
  }

  fetchParticipatedMotions = async ({ id }: { id: string }): Promise<void> => {
    this.isLoadingParticipatedMotions = true
    try {
      const participatedMotions = await API.insights.getParticipatedMotions({ id })

      runInAction(() => {
        this.setCurrentParticipatedMotions(participatedMotions)
      })
    } catch (error: unknown) {
      runInAction(() => {
        this.setCurrentParticipatedMotions(null)
      })
    } finally {
      runInAction(() => {
        this.isLoadingParticipatedMotions = false
      })
    }
  }

  fetchRevenueRetentionForecast = async (
    { dimension }: { dimension: string } = { dimension: 'overall' },
  ): Promise<void> => {
    this.isLoadingRevenueRetentionForecast = true
    try {
      const data = await API.insights.getRevenueRetentionForecast({ dimension })
      runInAction(() => {
        this.revenueRetentionForecast = data
      })
    } catch (error: unknown) {
      runInAction(() => {
        this.revenueRetentionForecast = null
      })
    } finally {
      runInAction(() => {
        this.isLoadingRevenueRetentionForecast = false
      })
    }
  }

  setTarget = async (payload: SetTargetPost) => {
    this.isLoadingTarget = true
    try {
      const accounts = await API.insights.setTarget(payload)
      if (accounts) {
        const accountData = prepareAccounts(accounts, payload.event)
        runInAction(() => {
          this.accountData = accountData
        })
      }
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingTarget = false
      })
    }
  }

  setSelectedTarget = (target: string) => {
    if (this.insights !== null) {
      const targetInsight = Object.entries(this.insights.events).filter((insight) => insight[0] === target)[0]
      this.selectedTarget = {
        ...targetInsight[1],
        short_name: targetInsight[0] as MlMetricCategoryEnum,
      }
    }
  }

  setSelectedDimension = (dimension: string) => {
    this.selectedDimension = dimension
  }

  setSelectedAccounts = (accountRowValue: AccountRowValue[]) => {
    this.selectedAccounts = accountRowValue
  }

  setCustomSegmentAccounts = (accountRowValue: AccountRowValue[]) => {
    this.customSegmentAccountData = { ...this.accountData, tableRows: accountRowValue }
  }

  setSelectedCustomSegmentAccounts = (accountRowValue: AccountRowValue[]) => {
    this.selectedCustomSegmentAccounts = accountRowValue
  }

  setIsRecalculating = (value: boolean) => {
    this.isRecalculating = value
  }

  setCurrentInsight = (insight: InsightV2) => {
    this.currentInsight = {
      ...insight,
    }
  }

  setCurrentAccountDetails = (accountDetails: AccountDetails) => {
    this.currentAccountDetails = accountDetails
  }

  setCurrentParticipatedMotions = (participatedMotions: ParticipatedMotion[] | null) => {
    this.currentParticipatedMotions = participatedMotions
  }

  /**
   * Sets the state of the Accounts table on the dashboard such that it persists between navigating to the Accounts pages.
   * @param {AccountForecastTableState | Partial<AccountForecastTableState>} state - The state of the account forecast table
   */
  setAccountForecastTableState = (state: AccountForecastTableState | Partial<AccountForecastTableState>) => {
    this.accountForecastTableState = {
      ...this.accountForecastTableState,
      ...state,
    }
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
