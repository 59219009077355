import { Row } from 'antd'
import { Link } from 'react-router-dom'

import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import { Heading } from 'components/common/Heading/Heading'

interface IntegrationHeaderProps {
  integrationId: string
  displayName: string
  children?: JSX.Element | JSX.Element[] | string
  iconUrl: string
  iconAlt: string
  linkTo: string
}

export const IntegrationHeader = (props: IntegrationHeaderProps) => {
  const { integrationId, displayName, children, iconUrl, iconAlt, linkTo } = props

  return (
    <Row align='middle' className='m-b-20' key={`header-${integrationId}`}>
      <Link className='integration-header-back-link' to={linkTo}>
        {'<'} Back
      </Link>
      <DynamicImage iconUrl={iconUrl} iconAlt={iconAlt} />
      <Heading level='1' variant='2' className='c-source--name'>
        {displayName}
      </Heading>
      {children}
    </Row>
  )
}
export default IntegrationHeader
