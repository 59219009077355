import { Tag } from 'antd'
import classNames from 'classnames'

import { getStatusIcon } from 'pages/IntegrationsV2/utils'

import type { IntegrationV2ConnectionStatus } from 'models/Integration.model'

interface ConnectionStatusProps {
  text: string
  status: IntegrationV2ConnectionStatus
}

export const ConnectionStatus = ({ text, status }: ConnectionStatusProps) => {
  const icon = getStatusIcon(status)
  return (
    <Tag
      icon={icon}
      className={classNames(`connection-status`, {
        'connection-status--success': status === 'Live',
        'connection-status--processing': status === 'Processing',
        'connection-status--error': status === 'Error',
      })}>
      {text}
    </Tag>
  )
}
