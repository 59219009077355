import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'

import { Card, ConnectedStatus } from 'components/common'
import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import Sandbox from 'configs/sandbox'
import { getIconUrl } from 'pages/Integrations/utils'

import type { Integration } from 'models/Integration.model'

const IntegrationCard = ({ connections, id, displayName, name, status }: Integration) => {
  return (
    <Card variant='1' key={id} className='integrations-main-card'>
      <Row className='c-flex--center'>
        <Col xs={1} md={1} lg={1} xl={1}>
          <DynamicImage iconUrl={getIconUrl(id)} iconAlt={displayName || name || ''} />
        </Col>
        <Col xs={13} md={10} lg={12} xl={13}>
          <span className='c-source--name'>{displayName || name}</span>
        </Col>
        <Col xs={8} md={6} lg={6} xl={5} className='c-flex--start'>
          <ConnectedStatus connections={connections} status={connections?.length > 1 ? status : 'Connected'} />
        </Col>
        <Col xs={22} md={6} lg={5} xl={5}>
          {connections?.length ? (
            <Link
              className={`button button-secondary button-XL ${Sandbox.isEnabled() && 'button--disabled'}`}
              to={!Sandbox.isEnabled() ? `/integrations/${id}` : ''}
              style={{ width: '145px' }}
              data-testid='edit-integration-old'>
              Edit
            </Link>
          ) : (
            <Link
              className={`button button-primary button-XL ${Sandbox.isEnabled() && 'button--disabled'}`}
              to={!Sandbox.isEnabled() ? `/integrations/${id}/add` : ''}
              data-testid='connect-button'
              style={{ width: '145px' }}>
              Connect
            </Link>
          )}
        </Col>
      </Row>
    </Card>
  )
}
IntegrationCard.displayName = 'IntegrationCard'

export default IntegrationCard
