import { Form, Input, Spin, Alert } from 'antd'
import { confirmSignIn, signIn, signInWithRedirect } from 'aws-amplify/auth'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, Heading, IconMagnifyLogoBlue } from 'components/common'
import useStore from 'store/useStore'

export function SignIn(): JSX.Element {
  const { userStore } = useStore()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const navigate = useNavigate()

  const [showPasswordField, setShowPasswordField] = useState<boolean>(false)
  const [showNewPasswordField, setShowNewPasswordField] = useState<boolean>(false)
  const [showMFACodeField, setShowMFACodeField] = useState<boolean>(false)

  const onFinish = async (values: { email: string; password: string; newpassword: string; mfaCode: string }) => {
    const { email, password, newpassword, mfaCode } = values
    try {
      setLoading(true)

      const idResponse = await userStore.getIdentity(email)
      if (idResponse) {
        return await signInWithRedirect({ provider: { custom: idResponse.identity } })
      }

      if (!password) {
        setLoading(false)
        setShowPasswordField(true)
        return
      }

      const { nextStep } = await signIn({ username: email, password })
      switch (nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE': {
          // 400: {"__type":"CodeMismatchException","message":"Invalid code received for user"}
          if (mfaCode) {
            await confirmSignIn({ challengeResponse: mfaCode })
            setLoading(true)
            setError(null)
          } else {
            setLoading(false)
            setShowMFACodeField(true)
            setError(null)
          }

          break
        }
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED': {
          // 400: {"__type":"InvalidPasswordException","message":"Password does not conform to policy: Password not long enough"}
          if (newpassword) {
            await confirmSignIn({
              challengeResponse: newpassword,
            })
          } else {
            setLoading(false)
            setShowNewPasswordField(true)
            setError(null)
          }

          break
        }
        case 'RESET_PASSWORD': {
          // 400: {"__type":"PasswordResetRequiredException","message":"Password reset required for the user"}
          navigate(`/reset`)
          break
        }
        case 'DONE': {
          break
        }
        default: {
          console.error('Unknown nextStep.signInStep', nextStep)
          setLoading(false)
          setError(null)
        }
      }
    } catch (error: unknown) {
      setLoading(false)
      if (error instanceof Error) {
        setError(new Error('Invalid username or password'))
      }
    }
  }

  return (
    <div className='login c-sign-in' data-testid='sign-in'>
      <IconMagnifyLogoBlue height={100} />
      <Card className='c-sign-in__container' variant='2'>
        <Spin size='large' spinning={loading}>
          <Form layout='vertical' onFinish={onFinish}>
            <Heading level='3' variant='3'>
              Access your account
            </Heading>

            <Form.Item
              label='Email'
              name='email'
              rules={[{ required: true, message: 'Please input your email!' }]}
              hidden={showMFACodeField}>
              <Input placeholder='Your email address' type='email' autoComplete='username' data-testid='email' />
            </Form.Item>

            {showPasswordField && (
              <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
                hidden={showMFACodeField}>
                <Input.Password
                  placeholder='Your password'
                  type='password'
                  autoComplete='current-password'
                  data-testid='password'
                />
              </Form.Item>
            )}

            {showNewPasswordField && (
              <Form.Item
                label='New Password'
                name='newpassword'
                validateStatus={showNewPasswordField ? 'warning' : ''}
                help='Please enter your new password'>
                <Input.Password placeholder='Strong password' data-testid='newpassword' />
              </Form.Item>
            )}

            {showMFACodeField && (
              <Form.Item
                label='MFA Code'
                name='mfaCode'
                validateStatus={showMFACodeField ? 'warning' : ''}
                rules={[
                  {
                    required: true,
                    message: 'Please input the generated MFA code on your device',
                  },
                  {
                    pattern: /^\d*$/,
                    message: 'MFA code must be a numeric value',
                  },
                ]}>
                <Input
                  className='c-sign-in-mfa-modal-input'
                  placeholder='MFA Code'
                  autoComplete='off'
                  data-testid='mfaCode'
                />
              </Form.Item>
            )}

            {error && <Alert message={error.message} type='error' data-testid='error-message' />}

            <Button
              text={showPasswordField ? 'Sign in' : 'Next'}
              htmlType='submit'
              style={{ minWidth: '200px', display: 'block', margin: '0 auto' }}
              testId='submit'
            />

            {!showMFACodeField && showPasswordField && (
              <p className='c-sign-in__reset' data-testid='reset-password'>
                Don't remember your password? <Link to='/reset'>Reset password</Link>
              </p>
            )}
          </Form>
        </Spin>
      </Card>
    </div>
  )
}
