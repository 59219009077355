import { Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { DropdownArrowDown } from 'components/common/Icons/Icons'
import {
  criteriaItemKey,
  filteredSelectOptions,
} from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/SingleSelect/utils'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { CreateActionFields, ExtendedSelectOptions, Item, SelectOptions } from 'models/motion/motionBuilder.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

interface SingleSelectProps {
  item: Item | CreateActionFields
  autoFocus?: boolean
  defaultOptions?: SelectOptions[] | ExtendedSelectOptions[]
  disabled?: boolean
  isAction?: boolean
  updateValue: (
    inputType: string,
    newVal: number | string | boolean | moment.Moment | moment.Moment[] | CreateSlackMessagePayload | null,
  ) => void
}

export const SingleSelect = observer(
  ({ item, autoFocus, defaultOptions, disabled, updateValue, isAction }: SingleSelectProps) => {
    const { metadataStore } = useStore()
    const { criteriaSelect, loadCriteriaInputOptions } = metadataStore

    useMetadataDisplayErrorNotification(metadataStore)

    const options: ExtendedSelectOptions[] = defaultOptions?.length
      ? defaultOptions
      : ((filteredSelectOptions(
          item,
          criteriaSelect as unknown as { options: Map<string, CreateActionFields[]> },
          isAction,
        ) as unknown as ExtendedSelectOptions[]) ?? [])

    const handleOnFocus = () => {
      if (!options?.length) {
        loadCriteriaInputOptions(item, criteriaItemKey({ item, isAction }), isAction).catch(console.error)
      }
    }

    return (
      <Select
        size='large'
        className='criteria-input_select_right'
        popupClassName='group-container__item-container__item__select-input-range__dropdown'
        value={item.displayValue || item.value}
        suffixIcon={<DropdownArrowDown />}
        onChange={(e) => {
          updateValue('select', e as string | boolean)
        }}
        {...(disabled && { disabled: disabled })}
        defaultOpen={autoFocus}
        autoFocus={autoFocus}
        onFocus={() => handleOnFocus()}
        notFoundContent={criteriaSelect.isLoading && <Spin size='small' />}>
        {options?.length &&
          options.map((option, index: number) => (
            <Select.Option key={index} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
      </Select>
    )
  },
)
SingleSelect.displayName = 'SingleSelect'
