import Layout, { Content } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { IntegrationHeader } from 'components/common'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { Params } from 'react-router-dom'

const AddIntegrationPage = observer(() => {
  const { id = '' }: Readonly<Params<string>> = useParams()
  const { integrationsStore } = useStore()
  const { currentIntegration, get } = integrationsStore

  const integration = currentIntegration

  useEffect(() => {
    get(id).catch(console.error)
  }, [])

  useDisplayErrorNotification(integrationsStore)

  return (
    <div className='bg-secondary integrations-main'>
      <Layout className='container'>
        <Content className='content'>
          <div>
            {integration && (
              <IntegrationHeader
                integrationId={integration.id}
                displayName={integration.name}
                iconUrl={integration.iconUrl}
                iconAlt={integration.id}
                linkTo={`/integrations/${integration.id}`}
              />
            )}
          </div>
        </Content>
      </Layout>
    </div>
  )
})

export default AddIntegrationPage
