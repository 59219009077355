const demoHubspotEmailDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Onboarding_Unmanaged',
        validFor: null,
        value: 144323884413,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Onboarding_Managed',
        validFor: null,
        value: 144323884414,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Monthly Checkin',
        validFor: null,
        value: 144323884415,
      },
      {
        active: true,
        defaultValue: false,
        label: 'NPS Survey_Managed',
        validFor: null,
        value: 144323884416,
      },
      {
        active: true,
        defaultValue: false,
        label: 'NPS Survey_Unmanaged',
        validFor: null,
        value: 144323884416,
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Guide',
  },
}

const sandboxHubspotEmailDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Email',
        validFor: null,
        value: 144323884413,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Renewal Email',
        validFor: null,
        value: 144323884414,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Churn Prevention',
        validFor: null,
        value: 144323884415,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Increase licenses',
        validFor: null,
        value: 144323884416,
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Guide',
  },
}

const sandboxMarketoEmailDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Onboarding Email',
        validFor: null,
        value: 255323904711,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Training Courses',
        validFor: null,
        value: 255323904712,
      },
      {
        active: true,
        defaultValue: false,
        label: 'New Feature Launch',
        validFor: null,
        value: 255323904713,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Features Upsell',
        validFor: null,
        value: 255323904714,
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Campaign',
  },
}

const sandboxZendeskPriorityDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Urgent',
        validFor: null,
        value: 'urgent',
      },
      {
        active: true,
        defaultValue: false,
        label: 'High',
        validFor: null,
        value: 'high',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Normal',
        validFor: null,
        value: 'normal',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Low',
        validFor: null,
        value: 'low',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

// For values see status in this table: https://developer.zendesk.com/api-reference/ticketing/tickets/tickets/#json-format
const sandboxZendeskStatusDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'New',
        validFor: null,
        value: 'new',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Open',
        validFor: null,
        value: 'open',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Pending',
        validFor: null,
        value: 'pending',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Hold',
        validFor: null,
        value: 'hold',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Solved',
        validFor: null,
        value: 'solved',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Closed',
        validFor: null,
        value: 'closed',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoMarketoEmailCampaignDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Email',
        validFor: null,
        value: 'Welcome Email',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Renewal Reminder',
        validFor: null,
        value: 'Renewal Reminder',
      },
      {
        active: true,
        defaultValue: false,
        label: 'NPS Survey',
        validFor: null,
        value: 'NPS Survey',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoSalesforceForecastCategoryDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Pipeline',
        validFor: null,
        value: 'Pipeline',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Best Case',
        validFor: null,
        value: 'Best Case',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Commit',
        validFor: null,
        value: 'Commit',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Closed',
        validFor: null,
        value: 'Closed',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoSalesforceStageDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Assessment Needed-25',
        validFor: null,
        value: 'Assessment Needed-25',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Develop Proposal-50',
        validFor: null,
        value: 'Develop Proposal-50',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Client Presentation-75',
        validFor: null,
        value: 'Client Presentation-75',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Initiate Transfer-90',
        validFor: null,
        value: 'Initiate Transfer-90',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoSalesforceWhiteGloveDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Yes',
        validFor: null,
        value: 'Yes',
      },
      {
        active: true,
        defaultValue: false,
        label: 'No',
        validFor: null,
        value: 'No',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

export {
  demoHubspotEmailDropdown,
  sandboxHubspotEmailDropdown,
  sandboxMarketoEmailDropdown,
  sandboxZendeskPriorityDropdown,
  sandboxZendeskStatusDropdown,
  demoMarketoEmailCampaignDropdown,
  demoSalesforceForecastCategoryDropdown,
  demoSalesforceStageDropdown,
  demoSalesforceWhiteGloveDropdown,
}
