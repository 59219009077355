import type { Dispatch, SetStateAction } from 'react'

import type { CreateActionFields, CreateActionsPayload } from 'models/motion/motionBuilder.model'
import type { DynamicInputs } from 'models/slack-messages'

/**
 * Converts the payload from the Motion builder to a standardized format for dynamic inputs.
 * @param {CreateActionsPayload} payload The payload from the Motion builder that contains the dynamic inputs to convert
 * @returns {Record<string, CreateActionFields>} The dynamic inputs as a standadized format between Motions and email templates.
 */
export const convertPayloadToDynamicInputs = (payload: CreateActionsPayload): Record<string, CreateActionFields> => {
  const output: Record<string, CreateActionFields> = {}

  // Check for empty or invalid payloads
  if (!Array.isArray(payload.fields) || payload?.fields?.length === 0) {
    return output
  }

  /**
   * Adds dynamic inputs from message value.
   *
   * @param {Record<string, CreateActionFields>} output - The output object to which dynamic inputs will be added.
   * @param {CreateActionFields} field - The field containing dynamic inputs.
   * @returns {void}
   */
  const convertSlackMessageToDynamicInput = (output: Record<string, CreateActionFields>, field: CreateActionFields) => {
    const { dynamicInputs } = field.value as Record<string, DynamicInputs>

    Object.keys(dynamicInputs).forEach((dynamicInputkey) => {
      output[dynamicInputkey] = {
        key: '',
        value: '',
        type: '',
        isMessageContent: true,
        ...(dynamicInputs[dynamicInputkey] as object),
      }
    })
  }

  for (const field of payload.fields) {
    const isRichMessage = field.key === 'messageContent'

    if (
      field.key &&
      field.value !== null &&
      typeof field.value === 'object' &&
      (field.isDynamicInput || isRichMessage)
    ) {
      if (isRichMessage) {
        convertSlackMessageToDynamicInput(output, field)
      } else {
        output[field.key] = {
          key: '',
          value: '',
          type: '',
          ...field.value,
        }
      }

      if (field.defaultValue) {
        output[field.key].defaultValue = field.defaultValue
      }
    }
  }

  return output
}

export interface EmailDynamicInputProps {
  currentKey: string
  defaultValue: string
  radioOption: string
  setDynamicInputs: Dispatch<SetStateAction<Record<string, object>>>
}

/**
 * Saves the dynamic input for the email template.
 * @param {EmailDynamicInputProps} props The props for the email dynamic input
 */
export const handleSaveEmailDynamicInput = ({
  currentKey,
  defaultValue,
  radioOption,
  setDynamicInputs,
}: EmailDynamicInputProps) => {
  if (!setDynamicInputs) {
    return
  }
  if (radioOption === 'error') {
    setDynamicInputs((prev) => {
      // Remove the default value
      const withoutDefault = prev[currentKey] as Record<string, object>
      delete withoutDefault.defaultValue
      // Update the state with the updated dynamicInput
      return {
        ...prev,
        [currentKey]: { ...withoutDefault },
      }
    })
  } else {
    setDynamicInputs((prev) => ({
      ...prev,
      [currentKey]: {
        ...prev[currentKey],
        defaultValue,
      },
    }))
  }
}

export interface SegmentBuilderDynamicInputProps {
  currentKey: string
  defaultValue: string
  radioOption: string
  setPayload?: React.Dispatch<React.SetStateAction<CreateActionsPayload>>
  isMessageContent?: boolean
}

/**
 * Saves the dynamic input for the segment builder.
 * @param {SegmentBuilderDynamicInputProps} props The props for the segment builder dynamic input
 */
export const handleSaveSegmentBuilderDynamicInput = ({
  currentKey,
  defaultValue,
  radioOption,
  setPayload,
  isMessageContent,
}: SegmentBuilderDynamicInputProps) => {
  if (!setPayload) {
    return
  }
  if (radioOption === 'error') {
    setPayload((prev) => ({
      ...prev,
      // Map over the fields and remove the `defaultValue` key for the dynamic input that matches `currentKey`
      fields: removeDefaultValues({ prev, currentKey, isMessageContent }),
    }))
  } else {
    setPayload((prev) => ({
      ...prev,
      // Map over the fields and set the `defaultValue` for the dynamic input that matches `currentKey`
      fields: setDefaultValues({ prev, currentKey, defaultValue, isMessageContent }),
    }))
  }
}
interface SetDefaultValues {
  prev: CreateActionsPayload
  currentKey: string
  defaultValue: string
  isMessageContent?: boolean
}

/**
 * Adds default value to Slack message dynamic inputs.
 * @param {Record<string, CreateActionFields>} dynamicInputs The dynamic inputs of the Slack message.
 * @param {string} key The key for which default value will be set.
 * @param {string} value The default value to set.
 * @returns {Record<string, CreateActionFields>} The updated dynamic inputs.
 */
const addDefaultValueToMessageContent = (
  dynamicFields: Record<string, CreateActionFields>,
  targetKey: string,
  defaultValue: string,
) => {
  if (dynamicFields.hasOwnProperty(targetKey)) {
    dynamicFields[targetKey].defaultValue = defaultValue
  }

  return dynamicFields
}

/**
 * Sets default values for a specific key in an array of fields, optionally considering message content scenario.
 * @param {SetDefaultValues} options - The options for setting default values.
 * @param {CreateActionsPayload} options.prev - The payload containing fields.
 * @param {string} options.currentKey - The key for which default values will be set.
 * @param {string} options.defaultValue - The default value to set.
 * @param {boolean} [options.isMessageContent] - Flag indicating whether it's a message content scenario.
 * @returns {CreateActionFields[]} - The array of fields with updated default values.
 */
const setDefaultValues = ({
  prev,
  currentKey,
  defaultValue,
  isMessageContent,
}: SetDefaultValues): CreateActionFields[] => {
  return (prev.fields || [{ key: currentKey }]).map((field) => {
    if (isMessageContent && field.key === 'messageContent') {
      const { dynamicInputs } = field.value as Record<string, DynamicInputs>
      // editing messageContent is a different scenario
      return {
        ...field,
        value: {
          ...(field.value as object),
          dynamicInputs: addDefaultValueToMessageContent(
            dynamicInputs as Record<string, CreateActionFields>,
            currentKey,
            defaultValue,
          ),
        },
      }
    }

    if (field.key === currentKey) {
      return {
        ...field,
        defaultValue,
      }
    }

    return field
  }) as unknown as CreateActionFields[]
}

/**
 * Removes default values for a specific key in an array of fields, optionally considering message content scenario.
 * @param {Omit<SetDefaultValues, 'defaultValue'>} options - The options for removing default values.
 * @param {CreateActionsPayload} options.prev - The payload containing fields.
 * @param {string} options.currentKey - The key for which default values will be removed.
 * @param {boolean} [options.isMessageContent] - Flag indicating whether it's a message content scenario.
 * @returns {CreateActionFields[]} - The array of fields with removed default values.
 */
const removeDefaultValues = ({ prev, currentKey, isMessageContent }: Omit<SetDefaultValues, 'defaultValue'>) => {
  return (prev.fields || [{ key: currentKey }]).map((field) => {
    if (isMessageContent && field.key === 'messageContent') {
      const { dynamicInputs } = field.value as Record<string, DynamicInputs>

      // Remove defaultValue from Message
      if (dynamicInputs.hasOwnProperty(currentKey)) {
        delete (dynamicInputs[currentKey] as CreateActionFields).defaultValue
      }

      return {
        ...field,
        value: {
          ...(field.value as object),
          dynamicInputs,
        },
      }
    }

    if (field.key === currentKey) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { defaultValue, ...rest } = field
      return {
        ...rest,
      }
    }
    return field
  })
}

/**
 * Format the dynamic input for display in the modal.
 * @param {string} key The key of the dynamic input.
 * @param {CreateActionFields} value The value of the dynamic input.
 * @param {CreateActionsPayload} [payload] The payload from the Motion builder that contains the dynamic inputs.
 * @returns {string} The formatted dynamic input for display in the modal.
 * @example
 * displayKey('salesforce_Contact_Name', { ... }) ➜ 'salesforce_Contact_Name: Full Name' // Email Templates
 * displayKey('senderName', { ... }) ➜ 'senderName: Salesforce / Contact / Full Name' // Segment Builder
 * displayKey('senderName', { ... }, { ... }) ➜ 'Sender Name: Salesforce / Contact / Full Name' // Segment Builder
 */
export const displayKey = (key: string, value: CreateActionFields, payload?: CreateActionsPayload): string => {
  if (payload?.fields && value?.platform && value?.magnifyDisplayName && value?.field) {
    // Capitalize the platform
    const displayPlatform = value.platform[0].toUpperCase() + value.platform.substring(1).toLowerCase()

    // Get the name of the field from the payload
    let name = key
    const targetFieldForm = payload?.fields.find((item) => item.key === key)
    if (targetFieldForm?.name) {
      name = targetFieldForm.name
    }

    return `${name}: ${displayPlatform} / ${value?.magnifyDisplayName} / ${value.field}`
  } else if (value?.platform && value?.object && value?.field) {
    return `${key}: ${value.field}`
  }
  return key
}

/**
 * Format key to show a human friendly name.
 * @param {string} key The key of the dynamic input.
 * @param {CreateActionsPayload} [payload] The payload from the Motion builder that contains the dynamic inputs.
 * @returns {string} The formatted name for display in the modal.
 * @example
 * getDisplayName('senderName', { ... }, { ... }) ➜ 'Sender Name'
 */
export const getDisplayName = (key: string, payload?: CreateActionsPayload): string => {
  // Get the name of the field from the payload
  let name = key
  const targetFieldForm = payload?.fields.find((item) => item.key === key)
  if (targetFieldForm?.name) {
    name = targetFieldForm.name
  }
  return name
}
