import type { Elements, FlowElement } from 'react-flow-renderer'

import type { MetadataTypes } from 'models/metadata.model'
import type {
  AggregationData,
  ConfigPanelPayload,
  CurrentUsedAggregation,
  SegmentBuilderData,
} from 'models/motion/motionBuilder.model'

export const aggregationsDataUtils = {
  title: 'Create calculated data with',
  nameLabel: 'Name',
  aggregationLabel: 'Aggregation',
  aggregationLevelLabel: 'Aggregation level',
  namePlaceholder: 'Describe this calculated data here...',
  filtersPlaceholder: 'You can filter the records for aggregation by adding filters',
  removeModalTitleBeforeTag: 'Are you sure you want to remove',
  removeModalTitleAfterTag: 'from the Motion?',
  removeBodyContent: ' Note that the following rules are using the aggregation you want to remove:',
}

export const emptyAggregationData = (name: string) => {
  return {
    name,
    aggregationType: '',
    aggregationLevel: '',
  }
}

export const removeAggregationFromPayload = ({
  payload,
  aggregation,
}: {
  payload: ConfigPanelPayload
  aggregation: CurrentUsedAggregation
}): ConfigPanelPayload => {
  const { groupIndex, itemIndex } = aggregation
  const newPayload = { ...payload }

  if (newPayload?.groups?.[groupIndex]?.groups?.[itemIndex]) {
    newPayload.groups[groupIndex].groups.splice(itemIndex, 1)
  }

  if (newPayload?.groups?.[groupIndex]?.groups?.length === 0) {
    newPayload.groups.splice(groupIndex, 1)
  }

  return newPayload
}

export const removeAggregations = ({
  elements,
  payload,
  aggregations,
}: {
  elements: Elements
  payload: ConfigPanelPayload
  aggregations: CurrentUsedAggregation[]
}) => {
  const elementsCopy = [...elements]
  const payloadCopy = { ...payload }

  aggregations.reduceRight((accumulator, aggregation) => {
    const currentElement: FlowElement<SegmentBuilderData> | undefined = accumulator.find(
      (element) => element.id === aggregation.nodeId,
    )

    if (currentElement) {
      const currentElementPayload = currentElement.data?.payload?.exitCondition || currentElement.data?.payload
      const newPayload = removeAggregationFromPayload({
        payload: currentElementPayload as ConfigPanelPayload,
        aggregation,
      })

      if (currentElement.data?.payload?.exitCondition) {
        currentElement.data.payload.exitCondition = newPayload
      } else {
        currentElement.data!.payload = newPayload
      }
    } else {
      const currentPayload = payloadCopy.exitCondition || payloadCopy
      const newPayload = removeAggregationFromPayload({ payload: currentPayload as ConfigPanelPayload, aggregation })

      if (payloadCopy.exitCondition) {
        payloadCopy.exitCondition = { ...newPayload }
      } else {
        Object.assign(payloadCopy, newPayload)
      }
    }

    return accumulator
  }, elementsCopy)

  return { elementsCopy, payloadCopy }
}

export const isAggregationMetadataDifferent = (
  metadataItem: MetadataTypes,
  aggregationItem: AggregationData | null,
) => {
  return metadataItem.object?.toLowerCase() !== aggregationItem?.object?.toLowerCase()
}
