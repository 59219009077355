import dayjs from 'dayjs'
import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type {
  DateRangeProps,
  TenantActionTotals,
  TenantInMotionReportingTotals,
  TenantMotionTotals,
} from 'models/observability.model'

export class ObservabilityStore implements ChildStore {
  isLoadingFetchTenantMotionActionTotals = false
  isLoadingFetchTenantMotionActionPastWeeks = false
  isLoadingFetchTenantMotionRangeTotals = false
  isLoadingFetchTenantInMotionReportingTotals = false

  tenantMotionsActionTotals: TenantActionTotals | null = null
  tenantMotionActionPastWeeks: { [key: string]: TenantActionTotals } | null = null
  tenantMotionRangeTotals: { [key: string]: TenantMotionTotals } | null = null
  tenantInMotionReportingTotals: TenantInMotionReportingTotals | null = null

  apiErrorObservability: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.isLoadingFetchTenantMotionActionTotals = false
    this.isLoadingFetchTenantMotionActionPastWeeks = false
    this.isLoadingFetchTenantMotionRangeTotals = false
    this.isLoadingFetchTenantInMotionReportingTotals = false

    this.tenantMotionsActionTotals = null
    this.tenantMotionActionPastWeeks = null
    this.tenantMotionRangeTotals = null
    this.tenantInMotionReportingTotals = null

    this.apiErrorObservability = null
  }

  fetchTenantMotionActionTotals = async (): Promise<void> => {
    if (this.tenantMotionsActionTotals) {
      return
    }

    this.isLoadingFetchTenantMotionActionTotals = true
    try {
      const data = await API.observability.getObservabilityTenantActionTotals()

      runInAction(() => {
        this.tenantMotionsActionTotals = data
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingFetchTenantMotionActionTotals = false
      })
    }
  }

  fetchTenantMotionActionPastWeeks = async (options: DateRangeProps): Promise<void> => {
    const key = this.rangeToKeyConverter(options)
    if (this.tenantMotionActionPastWeeks?.[key]) {
      return
    }

    this.isLoadingFetchTenantMotionActionPastWeeks = true
    try {
      const data = await API.observability.getObservabilityTenantActionTotals(options)

      runInAction(() => {
        if (this.tenantMotionActionPastWeeks === null) {
          this.tenantMotionActionPastWeeks = {}
        }

        this.tenantMotionActionPastWeeks[key] = data
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingFetchTenantMotionActionPastWeeks = false
      })
    }
  }

  fetchTenantMotionRangeTotals = async (options: DateRangeProps): Promise<void> => {
    const key = this.rangeToKeyConverter(options)
    if (this.tenantMotionRangeTotals?.[key]) {
      return
    }

    this.isLoadingFetchTenantMotionRangeTotals = true
    try {
      const data = await API.observability.getObservabilityTenantMotionTotals(options)

      runInAction(() => {
        if (this.tenantMotionRangeTotals === null) {
          this.tenantMotionRangeTotals = {}
        }

        this.tenantMotionRangeTotals[key] = data
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingFetchTenantMotionRangeTotals = false
      })
    }
  }

  fetchTenantInMotionReportingTotals = async (motionId: string): Promise<void> => {
    this.isLoadingFetchTenantInMotionReportingTotals = true
    try {
      const data = await API.observability.getObservabilityInMotionReportingTotals(motionId)

      runInAction(() => {
        this.tenantInMotionReportingTotals = data
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoadingFetchTenantInMotionReportingTotals = false
      })
    }
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiErrorObservability = error
    })
  }

  rangeToKeyConverter = (options: DateRangeProps) => {
    return `${dayjs(options.dateFrom).format('MM/DD/YY')}-${dayjs(options.dateTo).format('MM/DD/YY')}`
  }
}
