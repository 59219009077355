import { Dropdown, Table, Button as AntBtn } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import { demoGetAllIntegrationsV2 } from 'api/mockResponses/demo/integrations.mock'
import EllipsisVertical from 'assets/images/ellipsis-vertical.svg?react'
import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import { getIconUrl } from 'pages/Integrations/utils'
import { ConnectionStatus } from 'pages/IntegrationsV2/ConnectionStatus/ConnectionStatus'
import { getLatestConnection } from 'pages/IntegrationsV2/utils'

import type { TableProps } from 'antd'

import { DataFormatEnum } from 'models/insights'
import type { IntegrationV2, IntegrationV2ConnectionStatus } from 'models/Integration.model'

interface DataType {
  key: string
  connection: {
    connectionId: string
    displayName: string
    category: string
  }
  status: string
  lastIngested: number | undefined
  actions: string
}

export const ConnectionsTable = () => {
  const integrationConnections = demoGetAllIntegrationsV2

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Connection',
      dataIndex: 'connection',
      key: 'connection',
      render: (connection: { connectionId: string; displayName: string; category: string }) => (
        <div className='connections-table__connection'>
          <DynamicImage iconUrl={getIconUrl(connection.connectionId)} iconAlt={connection.displayName} />
          <div className='connections-table__connection__info'>
            <p className='connections-table__connection__info__text'>{connection.displayName}</p>
            <p className='connections-table__connection__info__text--secondary'>{connection.category}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <ConnectionStatus text={status} status={status as IntegrationV2ConnectionStatus} />,
    },
    {
      title: 'Connections',
      dataIndex: 'numOfConnections',
      key: 'numOfConnections',
      render: (numOfConnections: string) => <p className='connections-table__text'>{numOfConnections}</p>,
    },
    {
      title: 'Last Ingested',
      dataIndex: 'lastIngested',
      key: 'lastIngested',
      render: (lastIngested: number | undefined) => {
        if (!lastIngested) return '-'
        return <p className='connections-table__text'>{dayjs(lastIngested).format('D[-]MMM[-]YY h:mm A')}</p>
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (actionsTotal: string) => {
        if (actionsTotal === '-') return <p className='connections-table__text'>{actionsTotal}</p>

        return (
          <p className='connections-table__text'>
            {formatNumber({
              number: actionsTotal,
              format: DataFormatEnum.CurrencyKMB,
              decimal: 1,
            }).slice(1)}
          </p>
        )
      },
    },
    {
      title: '',
      key: 'actionBtn',
      render: (_: any, data: any) => {
        return (
          <Dropdown overlayClassName='' placement='bottomRight' trigger={['click']}>
            <AntBtn data-testid='motion-actions-button' className=''>
              <EllipsisVertical />
            </AntBtn>
          </Dropdown>
        )
      },
    },
  ]

  const getTableData = (integrations: IntegrationV2[]) => {
    return integrations.reduce((acc: DataType[], integration) => {
      const status = integration.connections?.some((connection) => connection.status === 'Error')
        ? 'Error'
        : integration.connections?.some((connection) => connection.status === 'Processing')
          ? 'Processing'
          : 'Live'

      if (!integration.connections) return acc
      const data = {
        key: integration.id,
        connection: {
          connectionId: integration.id,
          displayName: integration.displayName,
          category: integration.category,
        },
        status: status,
        numOfConnections: integration?.connections?.length.toString() || '0',
        lastIngested: getLatestConnection(integration.connections).lastIngestedDate,
        actions: integration?.actions?.total.toString() || '-',
      }
      return [...acc, data]
    }, [])
  }

  const tableData = useMemo(() => getTableData(integrationConnections), [integrationConnections])

  return (
    <div className='connections-table'>
      <Table columns={columns} dataSource={tableData} pagination={false} />
    </div>
  )
}
