import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AccountList from 'components/MotionDetails/AccountList'
import EmailStatsTable from 'components/MotionDetails/EmailStatsTable'
import ExecutedActionsSandbox from 'components/MotionDetails/ExecutedActionsSandbox'
import GoalMetric from 'components/MotionDetails/GoalMetric'
import MotionDetailsHeader from 'components/MotionDetails/Header'
import {
  LoadingOperationalStatisticsMetrics,
  LoadingGoalMetrics,
  LoadingHeader,
  LoadingMachineLearningMetrics,
  LoadingEmailStats,
} from 'components/MotionDetails/Loading/index'
import MachineLearningMetric from 'components/MotionDetails/MachineLearningMetric'
import OperationalStats from 'components/MotionDetails/OperationalStats'
import Tabs from 'components/MotionDetails/Tabs'
import Demo from 'configs/demo'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import Sandbox from 'configs/sandbox'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

const MotionDetailsPage = observer(() => {
  const navigate = useNavigate()

  const { reportingStore, motionStore } = useStore()
  const {
    loading,
    getMotion,
    getGoalMetrics,
    getMachineLearningMetrics,
    getAccountList,
    getMotionOperationalStatistics,
    getEmailStats,
  } = reportingStore
  const { get } = motionStore
  const { id: playbookId, version } = useParams()
  const {
    reportingStore: {
      data: { motionOperationalStatistics, emailStatistics },
      isMotionOperationalStatisticsEmpty,
    },
    insightsStore: { selectedDimension },
  } = useStore()
  const { sandbox } = useFlags<LaunchDarklyFeatureFlags>()

  useEffect(() => {
    if (playbookId && version) {
      get({ playbookId, version: Number.parseInt(version, 10) }).catch(console.error)
      getMotion({ playbookId, version: Number(version) })
        .then((motion) => {
          getMotionOperationalStatistics(playbookId).catch(console.error)
        })
        .catch(console.error)
      getGoalMetrics({ journeyId: playbookId, weeks: 10 }).catch(console.error)
      getMachineLearningMetrics({ journeyId: playbookId, weeks: 10 }).catch(console.error)
      getAccountList({
        journeyId: playbookId,
        version,
        dimension: selectedDimension,
      }).catch(console.error)
      getEmailStats({ journeyId: playbookId }).catch(console.error)
    }
  }, [Sandbox.isEnabled(), Demo.mockApiIsEnabled()])

  const {
    reportingStore: {
      data: { goalMetrics, machineLearningMetrics },
      dataTimeFrame: { goalMetricsTimeFrame, machineLearningMetricsTimeFrame },
      isGoalMetricsEmpty,
      isMachineLearningMetricsEmpty,
      isEmailStatsEmpty,
    },
  } = useStore()

  const handleMLMenuClick = (selectedTimeFrame: number) => {
    getMachineLearningMetrics({ journeyId: playbookId ?? '', weeks: selectedTimeFrame }).catch(console.error)
  }

  useDisplayErrorNotification(reportingStore)

  const handleGoalMenuClick = (selectedTimeFrame: number) => {
    getGoalMetrics({ journeyId: playbookId ?? '', weeks: selectedTimeFrame }).catch(console.error)
  }

  const handleClickClose = () => navigate(`/motions`)
  const handleClickViewMotion = () => navigate(`/motions/motion/${playbookId}/${version}`)

  return (
    <Layout className='container'>
      <Content className='content'>
        {loading.isMotionLoading ? (
          <LoadingHeader />
        ) : (
          <MotionDetailsHeader onClickClose={handleClickClose} onClickViewMotion={handleClickViewMotion} />
        )}
        {!loading.isMotionLoading && (
          <Tabs
            isOperationalStatisticsEmpty={isMotionOperationalStatisticsEmpty}
            isGoalMetricsEmpty={isGoalMetricsEmpty}
            isMachineLearningMetricsEmpty={isMachineLearningMetricsEmpty}
            isEmailStatsEmpty={isEmailStatsEmpty}
          />
        )}
        {loading.isMotionOperationalStatisticsLoading ? (
          <LoadingOperationalStatisticsMetrics />
        ) : (
          !isMotionOperationalStatisticsEmpty && (
            <OperationalStats
              operationalStatistics={motionOperationalStatistics}
              isOperationalStatisticsEmpty={isMotionOperationalStatisticsEmpty}
            />
          )
        )}
        {sandbox && <ExecutedActionsSandbox />}
        {loading.isGoalMetricsLoading ? (
          <LoadingGoalMetrics />
        ) : (
          !isGoalMetricsEmpty && (
            <GoalMetric
              metrics={goalMetrics}
              timeFrame={goalMetricsTimeFrame}
              isEmpty={isGoalMetricsEmpty}
              onSelectionChange={handleGoalMenuClick}
            />
          )
        )}
        {loading.isMachineLearningMetricsLoading ? (
          <LoadingMachineLearningMetrics />
        ) : (
          !isMachineLearningMetricsEmpty && (
            <MachineLearningMetric
              metrics={machineLearningMetrics}
              timeFrame={machineLearningMetricsTimeFrame}
              isEmpty={isMachineLearningMetricsEmpty}
              onSelectionChange={handleMLMenuClick}
            />
          )
        )}
        {loading.isEmailStatsLoading ? (
          <LoadingEmailStats />
        ) : (
          !isEmailStatsEmpty && <EmailStatsTable data={emailStatistics} loading={loading.isEmailStatsLoading} />
        )}
        <AccountList journeyId={playbookId} journeyVersion={version} />
      </Content>
    </Layout>
  )
})
MotionDetailsPage.displayName = 'MotionDetailsPage'

export default MotionDetailsPage
