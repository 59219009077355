import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { demoGetAllIntegrationsV2 } from 'api/mockResponses/demo/integrations.mock'
import { Button, Heading } from 'components/common'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import { AvailableConnections } from 'pages/IntegrationsV2/AvailableConnections/AvailableConnections'
import { ConnectionsTable } from 'pages/IntegrationsV2/ConnectionsTable/ConnectionsTable'
import { StatCard } from 'pages/IntegrationsV2/StatCard/StatCard'
import {
  getActionsExecutedStatCardData,
  getLatestIngestStatCardData,
  getLiveConnectionsStatCardData,
} from 'pages/IntegrationsV2/utils'

export const IntegrationsV2 = observer(() => {
  const { integrationsV2 } = useFlags<LaunchDarklyFeatureFlags>()
  const navigate = useNavigate()

  const integrations = demoGetAllIntegrationsV2

  // Redirect to integrations page if the feature flag is not enabled
  // Temporary until feature flag is removed
  useEffect(() => {
    if (!integrationsV2) {
      navigate(`/integrations`)
    }
  }, [integrationsV2])

  const liveConnectionsStatCardData = useMemo(() => getLiveConnectionsStatCardData(integrations), [integrations])
  const latestIngestStatCardData = useMemo(() => getLatestIngestStatCardData(integrations), [integrations])
  const actionsExecutedStatCardData = useMemo(() => getActionsExecutedStatCardData(integrations), [integrations])

  return (
    <main className='integrations'>
      <div className='integrations__header'>
        <div className='container integrations__header__container'>
          <Heading variant='2' level='2' className='integrations__header__title'>
            Integrations
          </Heading>
          <Button text='Add Connection' size='L' /> {/* Adding in a later ticket */}
        </div>
      </div>
      <div className='container'>
        <div className='content content__spacing--lg'>
          <section className='integrations__stat-cards'>
            <Heading variant='1' level='1' className='integrations__stat-cards__title'>
              Your data working together
            </Heading>

            <div className='integrations__stat-cards__list'>
              <StatCard {...liveConnectionsStatCardData} />
              <StatCard {...latestIngestStatCardData} />
              <StatCard {...actionsExecutedStatCardData} />
            </div>
          </section>

          {integrations && integrations.length > 0 && (
            <section className='integrations__connections'>
              <div className='integrations__connections__heading'>
                <Heading variant='2' level='2' className='integrations__title'>
                  Your Connections
                </Heading>
                <Button type='secondary' text='Add Connection' size='L' />
              </div>

              <ConnectionsTable />
            </section>
          )}

          <section className='integrations__available-connections'>
            <Heading variant='2' level='2' className='integrations__title integrations__available-connections__title'>
              Available Connections
            </Heading>

            <AvailableConnections integrations={integrations} />
          </section>
        </div>
      </div>
    </main>
  )
})
IntegrationsV2.displayName = 'IntegrationsV2'
