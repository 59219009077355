import classNames from 'classnames'

import {
  OPERATIONAL_STATISTICS_METRICS,
  getAccountsAndUsersValue,
  getOperationStatisticsCardDescription,
} from 'components/MotionDetails/OperationalStats/utils'
import { LogoService } from 'services/Utils/logo'

import type { OperationalStatisticDetails } from 'models/reporting.model'

interface CardProps {
  isRound: boolean
  service: string
  metric: string
  value?: number | string
  description?: string
  justifyContentStart?: boolean
}

export const Card = ({ isRound, service, metric, value, description, justifyContentStart }: CardProps) => (
  <div
    className={classNames({
      'operational-stats__card': true,
      'justify-content-start': justifyContentStart,
    })}
    data-testid='operational-stats__card'>
    <div className='operational-stats__icon-wrapper'>
      {service && !isRound && (
        <div className='operational-stats__icon operational-stats__icon--service'>
          {LogoService.getSVGIcon(`${service}`)}
        </div>
      )}

      <div
        className={classNames({
          'operational-stats__icon operational-stats__icon--metric': true,
          'operational-stats__icon--metric--round': isRound,
        })}>
        {LogoService.getMetricIcon(`${metric}`)}
      </div>
    </div>

    <div className='operational-stats__details'>
      <h2 className='operational-stats__text operational-stats__text--title'>{value?.toLocaleString()}</h2>
      <p className='operational-stats__text operational-stats__text--description'>{description}</p>
    </div>
  </div>
)
Card.displayName = 'Card'

interface OperationalStatsCardProps {
  service: string
  metric: string
  stats: OperationalStatisticDetails
  actionValue?: string
  actionDescription?: string
}

const OperationalStatsCard = ({
  service,
  metric,
  stats,
  actionValue,
  actionDescription,
}: OperationalStatsCardProps) => {
  if (metric === OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL && actionValue && actionDescription) {
    return (
      <Card isRound={false} service={service} metric={metric} value={actionValue} description={actionDescription} />
    )
  }

  const isMotion = service === OPERATIONAL_STATISTICS_METRICS.JOURNEY
  const description = getOperationStatisticsCardDescription(metric)
  const value = isMotion ? getAccountsAndUsersValue({ ACCOUNTS: stats.ACCOUNTS, USERS: stats.USERS }) : stats.SUCCEEDED

  return <Card isRound={isMotion} service={service} metric={metric} value={value} description={description} />
}
OperationalStatsCard.displayName = 'OperationalStatsCard'

export default OperationalStatsCard
