import { Form, Modal } from 'antd'
import classNames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { observer } from 'mobx-react-lite'
import { Fragment, useEffect, useState } from 'react'

import {
  aggregationsDataUtils,
  emptyAggregationData,
  isAggregationMetadataDifferent,
} from 'components/common/AggregationsData/index.utils'
import BreadcrumbTag from 'components/common/BreadcrumbTag'
import Button from 'components/common/Button'
import { IconClose } from 'components/common/Icons/Icons'
import { EMPTY_GROUPS_PAYLOAD } from 'components/common/Utils/constants'
import { DataSourcePanel } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/DataSourcePanel/DataSourcePanel'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import { validateCriteriaPayload } from 'services/Utils/validator'
import useStore from 'store/useStore'

import type { ConfigPanelPayload, Item } from 'models/motion/motionBuilder.model'

const AggregationsData = observer(() => {
  const { aggregationsDataStore, metadataStore } = useStore()
  const {
    aggregationsOptions,
    currentItem,
    currentAggregationData,
    aggregationsDataModal,
    getAggregationOptions,
    saveAggregationData,
    setCurrentAggregationData,
    setDisplayAggregationModal,
    setAggregationName,
    setAggregationType,
    setAggregationLevel,
  } = aggregationsDataStore

  const [modalPayload, setModalPayload] = useState(EMPTY_GROUPS_PAYLOAD as ConfigPanelPayload)
  const [modalPayloadError, setModalPayloadError] = useState(false)

  useEffect(() => {
    if (aggregationsDataModal.isOpen) {
      if (isAggregationMetadataDifferent(metadataStore.currentItem, aggregationsDataStore.currentItem)) {
        metadataStore
          .get({
            platform: currentItem?.platform,
            object: currentItem?.object,
            solutionInstanceId: currentItem?.solutionInstanceId,
          })
          .catch(console.error)
      }

      setModalPayloadError(false)
      getAggregationOptions().catch(console.error)

      if (aggregationsDataModal.isEditing && currentItem) {
        setCurrentAggregationData(currentItem)
        setModalPayload((cloneDeep(currentItem.filters) as ConfigPanelPayload) || EMPTY_GROUPS_PAYLOAD)
      } else {
        setModalPayload(cloneDeep(EMPTY_GROUPS_PAYLOAD) as ConfigPanelPayload)
        setCurrentAggregationData({ ...emptyAggregationData(`${currentItem?.key}_aggregation`) })
      }

      metadataStore.setBreadCrumbItems([
        {
          name: currentItem?.platform ?? '',
          path: [currentItem?.platform ?? ''],
          entityType: 'platform',
        },
        {
          name: currentItem?.object ?? '',
          path: [currentItem?.object ?? ''],
          entityType: 'object',
          ...(currentItem?.magnifyDisplayName && { magnifyDisplayName: currentItem?.magnifyDisplayName }),
        },
      ])
    }

    if (isAggregationMetadataDifferent(metadataStore.currentItem, aggregationsDataStore.currentItem)) {
      metadataStore.setCurrentItem(null)
      metadataStore.setBreadCrumbItems([])
    }
  }, [aggregationsDataModal.isOpen])

  const handleSubmit = () => {
    const validCriteria: boolean = validateCriteriaPayload({ payload: modalPayload, requiredPayload: false })

    if (validCriteria) {
      saveAggregationData(modalPayload)
    } else {
      setModalPayloadError(true)
      setTimeout(() => {
        setModalPayloadError(false)
      }, 5000)
    }
  }

  return (
    <Modal
      open={aggregationsDataModal.isOpen}
      keyboard
      width={898}
      maskClosable={false}
      className='modal aggregations-data-modal'
      onCancel={() => setDisplayAggregationModal(false)}
      closeIcon={<IconClose className='btn__close' data-testid='modal-close' />}
      title={
        <>
          {aggregationsDataUtils.title}
          {currentItem?.platform && currentItem?.object && currentItem?.name && (
            <BreadcrumbTag
              breadcrumb={{
                platform: currentItem.platform,
                object: currentItem.object,
                field: currentItem.key ?? currentItem.field ?? '',
                ...(currentItem.magnifyDisplayName && { magnifyDisplayName: currentItem.magnifyDisplayName }),
              }}
            />
          )}
        </>
      }
      footer={[
        <Fragment key='modal__footer'>
          {modalPayloadError && (
            <label className='error-message' data-testid='error-message'>
              You cannot apply criteria without a value.
            </label>
          )}

          <Button
            text='Cancel'
            type='secondary'
            size='XL'
            onClickHandler={() => setDisplayAggregationModal(false)}
            testId='cancel-btn'
          />

          <Button
            text={aggregationsDataModal.isEditing ? 'Save' : 'Continue'}
            size='XL'
            className='m-l-10'
            onClickHandler={handleSubmit}
            testId='continue-btn'
          />
        </Fragment>,
      ]}>
      <Form className='modal__body' data-testid='aggregations-data__modal-body'>
        <div className='aggregations-data-modal__inputs'>
          <Form.Item
            data-testid='aggregations-data-modal__inputs--name'
            label={aggregationsDataUtils.nameLabel}
            name={aggregationsDataUtils.nameLabel}
            className={classNames({ 'required-item': true })}
            rules={[
              {
                required: true,
              },
            ]}>
            <FieldInput
              item={{ type: 'string', value: currentAggregationData.name } as Item}
              range={false}
              isAction={true}
              placeholder={aggregationsDataUtils.namePlaceholder}
              changeFieldValue={setAggregationName}
            />
          </Form.Item>

          <Form.Item
            data-testid='aggregations-data-modal__inputs--aggregation'
            label={aggregationsDataUtils.aggregationLabel}
            name={aggregationsDataUtils.aggregationLabel}
            className={classNames({ 'required-item': true })}
            rules={[
              {
                required: true,
              },
            ]}>
            <FieldInput
              item={{ type: 'select', value: currentAggregationData.aggregationType } as Item}
              range={false}
              isAction={true}
              defaultOptions={aggregationsOptions.aggregationTypes}
              changeFieldValue={setAggregationType}
            />
          </Form.Item>

          <Form.Item
            data-testid='aggregations-data-modal__inputs--aggregation-level'
            label={aggregationsDataUtils.aggregationLevelLabel}
            name={aggregationsDataUtils.aggregationLevelLabel}
            className={classNames({ 'required-item': true })}
            rules={[
              {
                required: true,
              },
            ]}>
            <FieldInput
              item={{ type: 'select', value: currentAggregationData.aggregationLevel } as Item}
              range={false}
              isAction={true}
              defaultOptions={aggregationsOptions.aggregationLevel}
              changeFieldValue={setAggregationLevel}
            />
          </Form.Item>
        </div>

        <div className='aggregations-data-modal__segment-builder segment-builder' data-testid='segment-builder'>
          <div className='aggregations-data-modal__segment-builder--header'>
            <strong>Filters</strong>
            <span>{aggregationsDataUtils.filtersPlaceholder}</span>
          </div>

          <DataSourcePanel
            displayHeader={false}
            payload={modalPayload}
            forceDisableDynamicInput={true}
            setPayload={setModalPayload}
          />
        </div>
      </Form>
    </Modal>
  )
})
AggregationsData.displayName = 'AggregationsData'

export default AggregationsData
