import { Form } from '@rjsf/antd'
import validator from '@rjsf/validator-ajv8'
import Spin from 'antd/lib/spin'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button, Card, IntegrationHeader } from 'components/common'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { getIconUrl } from 'pages/Integrations/utils'
import useStore from 'store/useStore'

import type { IChangeEvent } from '@rjsf/core'
import type { NavigateFunction, Params } from 'react-router-dom'

import type { RJSFSchema, UiSchema } from '@rjsf/utils/lib/types'

const uiSchema: UiSchema = {
  token: {
    'ui:widget': 'password',
    'ui:autocomplete': 'off',
  },
  password: {
    'ui:widget': 'password',
    'ui:autocomplete': 'off',
  },
  oauthTemplateUrl: {
    'ui:widget': 'hidden',
    'ui:autocomplete': 'off',
  },
}

// Submit handler
export const onSubmit =
  (
    type: string,
    code: string | null,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    postNew: (id: string, data: Record<string, string | Record<string, string>>) => Promise<Record<string, string>>,
    navigate: NavigateFunction,
  ) =>
  async (
    data: IChangeEvent<Record<string, string>, RJSFSchema, any>,
    _event: React.FormEvent<Record<string, string>>,
  ) => {
    setLoading(true)

    const configuration: Record<string, string> = {
      ...data.formData,
    }

    // Redirect to OAuth based on the constructed URL from the form data
    if (configuration.oauthTemplateUrl && !code) {
      // Pull out the template we want to fill in with our form data
      let oauthlink = configuration.oauthTemplateUrl
      // Replace all the variables in the oauthTemplate
      oauthlink = oauthlink.replace(/###(\w+)###/g, (match: string, key: string) => configuration[key] || match)
      // Redirect
      window.location.assign(oauthlink)
      return
    }

    // Clean up oauthTemplate
    delete configuration.oauthTemplateUrl

    // Extract displayName from the payload to add to the top level.
    const { displayName } = configuration
    delete configuration.displayName

    if (code) {
      configuration.temporaryCode = code
    }

    let output
    try {
      output = await postNew(type, {
        displayName,
        configuration,
      })
    } catch (error: unknown) {
      console.error(error)
    } finally {
      setLoading(false)
    }

    if (output) {
      localStorage.removeItem(`${type}-formData`)
      navigate('/integrations')
    }
  }

const AddIntegrationPageNew = observer(() => {
  const { id = '' }: Readonly<Params<string>> = useParams()

  const { integrationsStore } = useStore()
  const { currentIntegrationNew: currentIntegration, getNew, postNew } = integrationsStore
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})

  // Load saved data from localStorage when the component mounts
  useEffect(() => {
    const savedData = localStorage.getItem(`${currentIntegration?.key}-formData`)
    if (savedData) {
      setFormData(JSON.parse(savedData) as object)
    }
  }, [currentIntegration])

  const [queryParameters] = useSearchParams()
  const code = queryParameters.get('code')

  useEffect(() => {
    getNew(id, { code }).catch(console.error)
  }, [])

  useDisplayErrorNotification(integrationsStore)

  // Loop over properties and add uiSchema for each property that isSecret
  if (currentIntegration?.fieldConfig?.allOf) {
    for (const item of currentIntegration.fieldConfig.allOf) {
      if (item?.properties) {
        for (const [key, value] of Object.entries(item.properties)) {
          if (value && value.isSecret) {
            uiSchema[key] = {
              'ui:widget': 'password',
            }
          }
        }
      }
    }
  }

  // Update the state and save the form data to localStorage onChange, until we submit.
  const onChange = (data: IChangeEvent<Record<string, string>, RJSFSchema, any>, id?: string) => {
    const savedData = (JSON.parse(localStorage.getItem(`${currentIntegration?.key}-formData`) || '{}') as object) || {}
    const processData = { ...savedData, ...data.formData }
    setFormData(processData)
    localStorage.setItem(`${currentIntegration?.key}-formData`, JSON.stringify(processData))
  }

  return (
    <main className='bg-secondary integrations-main add-new-integration' data-testid='add-new-integration'>
      {currentIntegration && (
        <div className='container'>
          <IntegrationHeader
            integrationId={currentIntegration.key}
            displayName={currentIntegration.displayName}
            iconUrl={getIconUrl(currentIntegration.key)}
            iconAlt={currentIntegration.key}
            linkTo={`/integrations`}
          />
          <Card variant='1' key={id} className='integrations-main-card'>
            <Spin spinning={loading} size='large'>
              {currentIntegration?.oauthLink ? (
                <a
                  href={currentIntegration.oauthLink}
                  className='button button-primary button-XL'
                  data-testid='oauth-button'>
                  Connect to {currentIntegration.displayName}
                </a>
              ) : (
                <Form
                  formData={formData}
                  onChange={onChange}
                  onSubmit={onSubmit(currentIntegration.key, code, setLoading, postNew, navigate)}
                  schema={currentIntegration?.fieldConfig as RJSFSchema}
                  uiSchema={uiSchema}
                  validator={validator}
                  autoComplete='off'>
                  <Button
                    key='submit'
                    disabled={loading}
                    text={typeof code === 'string' ? `Connect to ${currentIntegration.displayName}` : 'Create'}
                    htmlType='submit'
                  />
                </Form>
              )}
            </Spin>
          </Card>
        </div>
      )}
    </main>
  )
})
AddIntegrationPageNew.displayName = 'AddIntegrationPageNew'

export default AddIntegrationPageNew
