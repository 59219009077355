import { Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { criteriaItemKey } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/SingleSelect/utils'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { CreateActionFields, Item, SelectOptions } from 'models/motion/motionBuilder.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

const { Option } = Select

interface MultipleSelectProps {
  item: Item | CreateActionFields
  autoFocus?: boolean
  disabled?: boolean
  isAction?: boolean
  updateValue: (
    inputType: string,
    newVal: number | string | moment.Moment | moment.Moment[] | CreateSlackMessagePayload | null,
  ) => void
  setFocusOnInput?: (focus: string) => void
}

export const MultipleSelect = observer(
  ({ item, autoFocus, disabled, isAction, updateValue, setFocusOnInput }: MultipleSelectProps) => {
    const { metadataStore } = useStore()
    const { criteriaSelect, loadCriteriaInputOptions } = metadataStore
    const key = criteriaItemKey({ item, isAction })

    const options = criteriaSelect.options.get(key)

    useEffect(() => {
      if (!options) {
        loadCriteriaInputOptions(item, key, isAction).catch(console.error)
      }
    }, [])

    useMetadataDisplayErrorNotification(metadataStore)

    return (
      <Select
        size='large'
        className='criteria-input_select_right'
        mode='multiple'
        maxTagCount='responsive'
        maxTagPlaceholder={(e) => {
          return `+${e.length}`
        }}
        popupClassName='group-container__item-container__item__select-input-range__dropdown'
        value={item.value}
        defaultOpen={autoFocus}
        autoFocus={autoFocus}
        onChange={(e) => {
          updateValue('select', e as string)
        }}
        onBlur={() => setFocusOnInput?.('')}
        {...(disabled && { disabled: disabled })}
        notFoundContent={criteriaSelect.isLoading && <Spin size='small' />}>
        {options?.length &&
          options.map((option: SelectOptions, index) => (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
    )
  },
)
MultipleSelect.displayName = 'MultipleSelect'
