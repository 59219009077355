import { Skeleton } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { Heading } from 'components/common'
import SupportModal from 'components/SupportModal'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import IntegrationCard from 'pages/Integrations/IntegrationCard'
import IntegrationCardNew from 'pages/Integrations/IntegrationCardNew'
import useStore from 'store/useStore'

import type { Integration, IntegrationNew } from 'models/Integration.model'

const Intergrations = observer(() => {
  const { helpCenterStore, integrationsStore } = useStore()
  const { getAll, getAllNew, isLoading, integrations, integrationsNew } = integrationsStore

  useEffect(() => {
    getAll().catch(console.error)
    getAllNew().catch(console.error)
    integrationsStore.setCurrentIntegration(null)
  }, [])

  useDisplayErrorNotification(integrationsStore)

  // Convert Integration arrays to keyed objects for easy lookup when processing
  const intOld: Record<string, Integration> = integrations.reduce((obj, item) => {
    let key = item['id']
    // Convert the keys to the new format to be consistent
    if (key === 'zendesk') {
      key = 'zendesk-support'
    }
    return {
      ...obj,
      [key]: { ...item },
    }
  }, {})

  const intNew: Record<string, IntegrationNew> = integrationsNew.reduce((obj, item) => {
    return {
      ...obj,
      [item.key]: { ...item },
    }
  }, {})

  // Merge the connections of the old integrations into the new integration and remove the old integration that overlaps
  for (const [key, value] of Object.entries(intNew)) {
    if (intOld[key]) {
      value.connections = intOld[key].connections
      value.status = intOld[key].status
      delete intOld[key]
    } else {
      value.connections = []
      value.status = 'Not Connected'
    }
  }

  const showSupportModal = () => helpCenterStore.setSupportModal({ isOpen: true })

  return (
    <main className='integrations-main bg-secondary'>
      <div className='container'>
        <Content className='content'>
          <SupportModal description={'Please contact us for help with editing this connection.'} />
          <Heading level='1' variant='1'>
            Integrations
          </Heading>
          {isLoading ? (
            <Skeleton />
          ) : (
            Object.values(intOld)
              .sort((a, b) => a.id.localeCompare(b.id))
              .map((integration) => <IntegrationCard key={integration.id} {...integration} />)
          )}
          {isLoading ? (
            <Skeleton />
          ) : (
            Object.values(intNew)
              .sort((a, b) => a.key.localeCompare(b.key))
              .map((integration) => (
                <IntegrationCardNew id={integration.key} {...integration} showSupportModal={showSupportModal} />
              ))
          )}
        </Content>
      </div>
    </main>
  )
})
Intergrations.displayName = 'Intergrations'

export default Intergrations
