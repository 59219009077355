import type { CarouselRef } from 'antd/lib/carousel'

import type { OperationalStatisticDetails } from 'models/reporting.model'

export const SEND_EMAIL_ALLOWED_EVENTS_ORDERED = ['PROCESSED', 'DELIVERED', 'OPEN', 'CLICK', 'BOUNCE']

export const MAGNIFY_SEND_EMAIL_EVENTS = {
  PROCESSED: 'PROCESSED',
  DELIVERED: 'DELIVERED',
  OPEN: 'OPEN',
  CLICK: 'CLICK',
  BOUNCE: 'BOUNCE',
}

export const OPERATIONAL_STATISTICS_METRICS = {
  EXECUTION_ID: 'executionId',
  HUBSPOT_SEND_EMAIL: 'HUBSPOT_SEND_TRANSACTIONAL_EMAIL',
  INTERCOM_CREATE_UPDATE_COMPANY: 'INTERCOM_CREATE_UPDATE_COMPANY',
  INTERCOM_CREATE_TICKET: 'INTERCOM_CREATE_TICKET',
  JOURNEY_ID: 'journeyId',
  JOURNEY: 'JOURNEY',
  MAGNIFY_SEND_EMAIL: 'MAGNIFY_SEND_EMAIL',
  MARKETO_SEND_EMAIL: 'MARKETO_SEND_EMAIL',
  MARKETO_OPEN_EMAIL: 'MARKETO_OPEN_EMAIL',
  MARKETO_CLICKED_LINK_EMAIL: 'MARKETO_CLICKED_LINK_EMAIL',
  SALESFORCE_CREATE_OPPORTUNITY: 'SALESFORCE_CREATE_OPPORTUNITY',
  SALESFORCE_UPDATE_OPPORTUNITY: 'SALESFORCE_UPDATE_OPPORTUNITY',
  SALESFORCE_UPDATE_ACCOUNT: 'SALESFORCE_UPDATE_ACCOUNT',
  SALESFORCE_CREATE_CASE: 'SALESFORCE_CREATE_CASE',
  SALESFORCE_CREATE_TASK: 'SALESFORCE_CREATE_TASK',
  GAINSIGHT_CREATE_CTA: 'GAINSIGHT_CREATE_CTA',
  GAINSIGHT_CREATE_TIMELINE_EVENT: 'GAINSIGHT_CREATE_TIMELINE_EVENT',
  GAINSIGHT_TRIGGER_EVENT: 'GAINSIGHT_TRIGGER_EVENT',
  SLACK_SEND_MESSAGE: 'SLACK_SEND_MESSAGE',
  SLACK_POST_TO_CHANNEL: 'SLACK_POST_TO_CHANNEL',
  PENDO_SEND_MESSAGE: 'PENDO_SEND_MESSAGE',
  PENDO_LAUNCH_GUIDE: 'PENDO_LAUNCH_GUIDE',
  ZENDESK_CREATE_TICKET: 'ZENDESK_CREATE_TICKET',
}

export const getMaxSlide = (slider: React.MutableRefObject<CarouselRef | null>) => {
  // AntD provides an any type for innerSlider
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const numOfSlidesToShow = slider.current?.innerSlider.props.slidesToShow
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const numOfSlides = slider.current?.innerSlider.state.slideCount
  return numOfSlides - numOfSlidesToShow
}

export const handleSlideChange = (
  slidesToShow: number,
  slideCount: number,
  current: number,
  next: number,
  setShowArrow: React.Dispatch<
    React.SetStateAction<{
      left: boolean
      right: boolean
    }>
  >,
) => {
  const maxSlide = slideCount - slidesToShow

  if (next < 1) {
    setShowArrow({ left: false, right: true })
    return
  }
  if (current === next || next === maxSlide) {
    setShowArrow({ left: true, right: false })
    return
  }
  setShowArrow({ left: true, right: true })
}

export const sortEmailStatisticCards = (statisticCards: [string, any][], knownOrderedArray: string[]) => {
  return statisticCards.sort((a, b) => {
    const indexOfA = knownOrderedArray.indexOf(a[0])
    const indexOfB = knownOrderedArray.indexOf(b[0])

    // If both elements are in the order array, compare their positions
    if (indexOfA !== -1 && indexOfB !== -1) {
      return indexOfA - indexOfB
    }

    // If one element is in the order array and the other is not, prioritise the one in the order array
    if (indexOfA !== -1) {
      return -1
    }
    if (indexOfB !== -1) {
      return 1
    }

    // If neither element is in the order array, sort them alphabetically
    return a[0].localeCompare(b[0])
  })
}

export const sortStatisticCards = (statisticCards: JSX.Element[]) => {
  return [...statisticCards].sort((a, b) => {
    if (a.key === 'JOURNEY') return -1
    if (b.key === 'JOURNEY') return 1
    return 0
  })
}

export const getMagnifySendEmailOperationalStatsCardDescription = (emailEvent: string) => {
  let description = ''

  switch (emailEvent) {
    case MAGNIFY_SEND_EMAIL_EVENTS.PROCESSED:
      description = 'Magnify emails sent'
      break
    case MAGNIFY_SEND_EMAIL_EVENTS.DELIVERED:
      description = 'Magnify emails delivered'
      break
    case MAGNIFY_SEND_EMAIL_EVENTS.OPEN:
      description = 'Magnify emails opened'
      break
    case MAGNIFY_SEND_EMAIL_EVENTS.CLICK:
      description = 'Magnify email links clicked'
      break
    case MAGNIFY_SEND_EMAIL_EVENTS.BOUNCE:
      description = 'Magnify emails bounced'
      break
    default:
      description = ''
      break
  }

  return description
}

export const getOperationStatisticsCardDescription = (metric: string) => {
  let description = ''

  switch (metric) {
    case OPERATIONAL_STATISTICS_METRICS.JOURNEY:
      description = `Accounts | Users`
      break
    case OPERATIONAL_STATISTICS_METRICS.HUBSPOT_SEND_EMAIL:
      description = 'Hubspot emails sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.INTERCOM_CREATE_TICKET:
      description = 'Intercom tickets created'
      break
    case OPERATIONAL_STATISTICS_METRICS.INTERCOM_CREATE_UPDATE_COMPANY:
      description = 'Intercom companies updated'
      break
    // Note: this is specifically only for Sandbox, since we process them as fake actions
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL:
      description = 'Magnify emails sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_SEND_EMAIL:
      description = 'Marketo emails sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_OPEN_EMAIL:
      description = 'Marketo emails opened'
      break
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_CLICKED_LINK_EMAIL:
      description = 'Marketo email links clicked'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_OPPORTUNITY:
      description = 'Salesforce opportunities created'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_UPDATE_OPPORTUNITY:
      description = 'Salesforce opportunities updated'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_UPDATE_ACCOUNT:
      description = 'Salesforce accounts updated'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_CASE:
      description = 'Salesforce cases created'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_TASK:
      description = 'Salesforce tasks created'
      break
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_CREATE_CTA:
      description = 'Gainsight CTAs created'
      break
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_CREATE_TIMELINE_EVENT:
      description = 'Gainsight timeline events created'
      break
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_TRIGGER_EVENT:
      description = 'Gainsight events triggered'
      break
    case OPERATIONAL_STATISTICS_METRICS.SLACK_SEND_MESSAGE:
      description = 'Slack messages sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.SLACK_POST_TO_CHANNEL:
      description = 'Slack channel messages sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.PENDO_SEND_MESSAGE:
      description = 'In-APP message sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.PENDO_LAUNCH_GUIDE:
      description = 'Pendo guides launched'
      break

    case OPERATIONAL_STATISTICS_METRICS.ZENDESK_CREATE_TICKET:
      description = 'Zendesk tickets created'
      break
    default:
      description = 'Actions Performed'
  }

  return description
}

export const getAccountsAndUsersValue = (stats: OperationalStatisticDetails) => {
  const accounts = numberFormatter(stats.ACCOUNTS)
  const users = numberFormatter(stats.USERS)
  return `${accounts} | ${users}`
}

const numberFormatter = (num: number | undefined) => {
  if (num === undefined) return num

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ]
  const removeTrailingZerosAfterDecimalPointRegex = /\.0+$|(\.\d*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value)

  return item
    ? (num / item.value).toFixed(1).replace(removeTrailingZerosAfterDecimalPointRegex, '$1') + item.symbol
    : '0'
}

// Temporary for demo purposes
export const DEMO_OPERATIONAL_STATS_DATA = {
  MARKETO: {
    MARKETO_SEND_EMAIL: {
      STATISTICS: {
        FAILED: 25,
        SUCCEEDED: 94,
      },
    },
  },
  GAINSIGHT: {
    GAINSIGHT_CREATE_CTA: {
      STATISTICS: {
        FAILED: 6,
        SUCCEEDED: 31,
      },
    },
  },
  SALESFORCE: {
    SALESFORCE_UPDATE_OPPORTUNITY: {
      STATISTICS: {
        FAILED: 1,
        SUCCEEDED: 8,
      },
    },
    SALESFORCE_CREATE_OPPORTUNITY: {
      STATISTICS: {
        FAILED: 28,
        SUCCEEDED: 17,
      },
    },
  },
  executionId: '176b8b04-8fcb-43d1-92dc-3a3f6db0b5cf',
  JOURNEY: {
    USERS: 1200,
    ACCOUNTS: 1869,
  },
}
