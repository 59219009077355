import type { CreateActionFields, Item } from 'models/motion/motionBuilder.model'

interface CriteriaItemKeyProps {
  item: Item | CreateActionFields
  itemKey?: string
  isAction?: boolean
}
export const criteriaItemKey = ({ item, itemKey, isAction }: CriteriaItemKeyProps) => {
  const key = `${item.platform}${item.object}${itemKey ?? item.key ?? item.field}`
  return isAction ? `action${key}` : key
}

/**
 * MAGPROD-969
 * Filter select 'playbook' options based on the value of another field ( type )
 * @param item Item | CreateActionFields
 * @param criteriaSelect object with options prop, derived from metadata store
 * @returns a list of options for the select input
 */
export const filteredSelectOptions = (
  item: Item | CreateActionFields,
  criteriaSelect: {
    options: Map<string, CreateActionFields[]>
  },
  isAction?: boolean,
) => {
  const { platform, object, key, ctaTypeValue } = item as CreateActionFields

  if (
    platform?.toLowerCase() === 'gainsight' &&
    object?.toLowerCase() === 'call_to_action' &&
    key?.toLowerCase() === 'playbook'
  ) {
    if (ctaTypeValue) {
      const criteriaItemKeyForType = criteriaItemKey({ item, itemKey: 'Type', isAction })

      const ctaTypeIdFromSelectedType =
        criteriaSelect.options.get(criteriaItemKeyForType)?.find((option) => option.value === ctaTypeValue)
          ?.ctaTypeId ?? ''

      return criteriaSelect.options
        .get(criteriaItemKey({ item, isAction }))
        ?.filter((option) => option.ctaTypeId === ctaTypeIdFromSelectedType)
    }
  }
  return criteriaSelect.options.get(criteriaItemKey({ item, isAction }))
}
